import React from 'react';
import PropTypes from 'prop-types';

import { Loading, useRecordContext } from 'react-admin';
import { startCase, isEmpty, get } from 'lodash';
import { isDefined } from '../../utils/validators';
import { useConstantContext } from '../ConstantsContext';
import ListChip, { colors } from '../designSystem/ListChip';

const ApplicationStatusChip = ({ source }) => {
  const record = useRecordContext();
  const applicationStatus = get(record, source);
  const { constants } = useConstantContext();
  const { decisionStatuses } = constants;

  if (isEmpty(decisionStatuses)) return <Loading />;
  if (!isDefined(applicationStatus)) return null;

  let chipColor;
  if (applicationStatus === decisionStatuses.REFERRED) chipColor = colors.orange;
  else if (applicationStatus === decisionStatuses.GRANTED) chipColor = colors.green;
  else if (applicationStatus === decisionStatuses.DENIED) chipColor = colors.red;
  else chipColor = colors.gray;

  if (applicationStatus === decisionStatuses.PARTNER_PENDING) chipColor = colors.gray;
  else if (applicationStatus === decisionStatuses.PARTNER_GRANTED) chipColor = colors.green;

  return <ListChip label={startCase(applicationStatus)} chipColor={chipColor} />;
};

ApplicationStatusChip.propTypes = {
  source: PropTypes.string.isRequired,
};

export default ApplicationStatusChip;
