import React from 'react';
import {
  ReferenceField,
  FunctionField,
  useRecordContext,
  Link,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import FieldsGroup from '../../utils/FieldsGroup';
import CurrencyField from '../../utils/CurrencyField';
import SimpleShowLabeled from '../../utils/SimpleShowLabeled';

import useUserPermissionsAndRoles from '../../hooks/useUserPermissionsAndRoles';

import AccordionGroup from '../../designSystem/Accordion';

import CreditDataInfo from '../../entityv2/CreditDataInfo';

const MerchantDetailsGroup = () => {
  const { t } = useTranslation();
  const { isFinancer } = useUserPermissionsAndRoles();
  const record = useRecordContext();

  if (!isFinancer) return null;

  return (
    <FieldsGroup
      title={(
        <span>
          {t('Merchant: ')}
          <Link to={`/entity-v2/merchant/${record.id}`} color="secondary">{record.mainAddress.name}</Link>
        </span>
      )}
    >
      <Grid container>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <CurrencyField label={t('Financial limit')} source="kpis.financingLimit" currencySource="kpis.currency" emptyText="-" />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <CurrencyField label={t('Available financing')} source="kpis.financingLimitAvailable" currencySource="kpis.currency" emptyText="-" />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <CurrencyField label={t('Factoring exposure')} source="kpis.orderOutstandingFinancedAmount" currencySource="kpis.currency" emptyText="-" />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <CurrencyField label={t('Factoring reserve')} source="kpis.factoringReserveAmount" currencySource="kpis.currency" emptyText="-" />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <CurrencyField label={t('Loans')} source="kpis.loanOutstandingFinancedAmount" currencySource="kpis.currency" emptyText="-" />
          </SimpleShowLabeled>
        </Grid>
      </Grid>
      <Box marginTop="1.5rem">
        <AccordionGroup title={t('Invoice history')}>
          <Grid container>
            <Grid item lg={4} md={6}>
              <SimpleShowLabeled>
                <FunctionField
                  label={t('Open invoices')}
                  render={() => (
                    record.kpis && record.kpis.orderTotalCount !== undefined ? (
                      <>
                        {`${record.kpis.orderTotalCount} (`}
                        <CurrencyField source="kpis.orderOutstandingInvoicedAmount" currencySource="kpis.currency" emptyText="-" />
                        )
                      </>
                    ) : '-'
                  )}
                />
              </SimpleShowLabeled>
            </Grid>
            <Grid item lg={4} md={6}>
              <SimpleShowLabeled>
                <CurrencyField label={t('Overdue invoices')} source="kpis.orderOverdueAmount" currencySource="kpis.currency" emptyText="-" />
              </SimpleShowLabeled>
            </Grid>
            <Grid item lg={4} md={6}>
              <SimpleShowLabeled>
                <FunctionField
                  label={t('Avg. repayment time')}
                  render={() => {
                    const days = record?.kpis?.averageRepaymentTime;
                    if (days === undefined) return '-';
                    const timeLabel = days > 0 ? t('overdue') : t('before due');
                    const dayLabel = Math.abs(days) === 1 ? t('day') : t('days');
                    return `${Math.abs(days)} ${dayLabel} ${timeLabel}`;
                  }}
                />
              </SimpleShowLabeled>
            </Grid>
          </Grid>
        </AccordionGroup>
        <AccordionGroup title={t('External data')} hasBorderBottom>
          <ReferenceField
            label={t('External rating')}
            source="entityCoreId"
            reference="entity-v2/credit-data"
            link={false}
            emptyText="No external data is fetched"
          >
            <CreditDataInfo />
          </ReferenceField>
        </AccordionGroup>
      </Box>
    </FieldsGroup>
  );
};

export default MerchantDetailsGroup;
