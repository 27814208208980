import { Button, useRecordContext } from 'react-admin';
import { useTranslation } from 'react-i18next';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import useCustomRpc from '../hooks/useCustomRpc';

const downloadDocumentOnSuccess = ({ data }) => {
  const { downloadUrl } = data;
  window.open(downloadUrl);
};

const OpenFileButton = ({
  path,
  condition,
  title,
}) => {
  const record = useRecordContext();
  const { t } = useTranslation();

  const {
    mutate: downloadDocument,
  } = useCustomRpc({
    httpMethod: 'GET',
    path,
    errorMessage: t('Could not retrieve file'),
    onSuccess: downloadDocumentOnSuccess,
  });

  const onClick = useCallback(() => {
    downloadDocument();
  }, [downloadDocument]);

  return condition(record) ? <Button onClick={onClick} variant="subAction"><>{title || t('Open PDF')}</></Button> : null;
};

OpenFileButton.propTypes = {
  path: PropTypes.string.isRequired,
  condition: PropTypes.func,
  title: PropTypes.string,
};

OpenFileButton.defaultProps = {
  condition: () => true,
  title: '',
};

const OpenInvoiceFileButton = () => {
  const { t } = useTranslation();
  const record = useRecordContext();
  const path = `invoice/${record.id}/download-v2`;

  return (
    <OpenFileButton
      condition={(invoice) => invoice && !!invoice.pdfGeneratedAt}
      path={path}
      title={t('Open')}
    />
  );
};

const OpenDeliveryConfirmationFileButton = (props) => {
  const { t } = useTranslation();
  const { orderId, id } = useRecordContext(props);
  const path = `order/${orderId}/delivery-confirmation/${id}/download`;

  return (
    <OpenFileButton
      condition={(dc) => !!(dc && dc.documents?.length > 0)}
      path={path}
      title={t('View')}
    />
  );
};

export { OpenDeliveryConfirmationFileButton, OpenInvoiceFileButton };
