import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import {
  useRecordContext,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import { EditModalButton } from '../designSystem/ModalButton';

import useUserPermissionsAndRoles from '../hooks/useUserPermissionsAndRoles';
import useCustomRpc from '../hooks/useCustomRpc';

import resources from '../../constants/resources';

import { useConstantContext } from '../ConstantsContext';
import useHasUserPermissions from '../hooks/useHasUserPermissions';
import KycFields from '../utils/KycFields';

const UpdateKycButton = ({ entityResource }) => {
  const record = useRecordContext();
  const {
    entityDetailsId,
    kyc,
  } = record;
  const {
    creditScore,
    creditScoreProvider,
    paymentScore,
    paymentScoreProvider,
    financerScore,
    expectedVolume,
  } = kyc;

  const { hasFactoring } = useUserPermissionsAndRoles();
  const { constants } = useConstantContext();
  const { userActions } = constants;
  const { t } = useTranslation();

  const canEditCustomerKyc = useHasUserPermissions(
    constants,
    userActions.SET_CUSTOMER_KYC,
  );
  const canEditMerchantKyc = useHasUserPermissions(
    constants,
    userActions.SET_MERCHANT_KYC,
  );

  const {
    mutate: updateKycInfo,
    isLoading,
  } = useCustomRpc({
    path: `entity-v2/${entityDetailsId}/kyc-info`,
    httpMethod: 'PUT',
    shouldRefresh: true,
    errorMessage: t('Could not update the kyc information'),
  });

  const onSubmit = useCallback((formData) => {
    const data = {
      kyc: {
        creditScore: formData.creditScore,
        creditScoreProvider: formData.creditScoreProvider,
        paymentScore: formData.paymentScore,
        paymentScoreProvider: formData.paymentScoreProvider,
        financerScore: formData.financerScore,
        expectedVolume: formData.expectedVolume,
      },
    };
    updateKycInfo(data);

    return true;
  }, [updateKycInfo]);

  if (!canEditMerchantKyc && entityResource.endsWith(resources.MERCHANT)) return null;
  if (!canEditCustomerKyc && entityResource.endsWith(resources.CUSTOMER)) return null;
  if (!hasFactoring) return null;

  return (
    <EditModalButton
      modalTitle={t('Update KYC information')}
      onClick={onSubmit}
      disabled={isLoading}
      width="25rem"
      formDefaultValue={{
        creditScore,
        creditScoreProvider,
        paymentScore,
        paymentScoreProvider,
        financerScore,
        expectedVolume,
      }}
    >
      <KycFields />
    </EditModalButton>
  );
};

UpdateKycButton.propTypes = {
  entityResource: PropTypes.string.isRequired,
};

export default UpdateKycButton;
