import React from 'react';
import {
  TextField,
  WrapperField,
} from 'react-admin';
import { useTranslation } from 'react-i18next';
import CurrencyField from '../utils/CurrencyField';
import FactoringAccountStatusChip from '../utils/FactoringAccountStatusChip';

import useUserPermissionsAndRoles from '../hooks/useUserPermissionsAndRoles';

import { useConstantContext } from '../ConstantsContext';

import Datagrid from '../designSystem/react-admin/Datagrid';
import List from '../designSystem/react-admin/list/List';
import ReferenceField, { EntityReferenceField } from '../designSystem/react-admin/ReferenceField';
import DateField from '../designSystem/react-admin/DateField';

import useFactoringAccountFilters from './useFactoringAccountFilters';
import useHasUserPermissions from '../hooks/useHasUserPermissions';
import TitleH1 from '../designSystem/TitleH1';

export const getFactoringAccountListTitle = (t) => t('Financings');

const FactoringAccountList = () => {
  const { constants } = useConstantContext();
  const { userActions } = constants;
  const { t } = useTranslation();

  const { isFinancer, isMerchant } = useUserPermissionsAndRoles();
  const canViewMerchant = useHasUserPermissions(
    constants,
    userActions.VIEW_MERCHANTS,
  );
  const canEditOrder = useHasUserPermissions(
    constants,
    userActions.CREATE_ORDERS,
  );
  const factoringFilters = useFactoringAccountFilters();

  return (
    <List
      title={<TitleH1 title={getFactoringAccountListTitle(t)} />}
      mainFilterInputs={factoringFilters}
      sort={{ field: 'createdAt', order: 'DESC' }}
      appLocation="home.factoring-account"
    >
      <Datagrid
        bulkActionButtons={false}
        rowClick="edit"
        rightAlignedColumns={['exposure', 'balance', 'status']}
      >
        <TextField label={t('Financer')} source="creditorName" sortable={false} />
        {canViewMerchant && (
          <EntityReferenceField
            label={t('Merchant')}
            source="debtorId"
            reference="entity-v2/merchant"
            link={({ entityDetailsId }) => `/entity-v2/merchant/${entityDetailsId}`}
          />
        )}
        <ReferenceField
          label={t('Order')}
          referenceSource="factoredOrder"
          source="orderReferenceNumber"
          reference="order"
          link={canEditOrder ? 'edit' : 'show'}
          FieldComponent={TextField}
        />
        <DateField label={t('Creation date')} source="createdAt" />
        <ReferenceField
          label={t('Financing date')}
          referenceSource="factoredOrder"
          source="financing.financedAt"
          reference="order"
          FieldComponent={DateField}
        />
        {isFinancer && <CurrencyField source="exposure" currencySource="currency" label={t('Exposure')} sortable={false} />}
        {isMerchant && <CurrencyField source="balance" currencySource="currency" label={t('Factoring balance / reserve')} coefficient={-1} sortable={false} />}
        {isMerchant && (
          <ReferenceField
            label={t('Order balance')}
            reference="order"
            referenceSource="factoredOrder"
            source="balance"
            FieldComponent={CurrencyField}
            FieldComponentProps={{ currencySource: 'currency' }}
          />
        )}
        <WrapperField source="status">
          <FactoringAccountStatusChip />
        </WrapperField>
      </Datagrid>
    </List>
  );
};

export default FactoringAccountList;
