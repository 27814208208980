import React from 'react';
import PropTypes from 'prop-types';

import {
  ArrayField,
  TextField,
  usePermissions,
  SelectField,
  FunctionField,
  ReferenceField,
  useRecordContext,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import UpdateAccountInvoiceSettingsButton from './UpdateAccountInvoiceSettingsButton';

import useIsCreditor from '../hooks/useIsCreditor';
import useUserPermissionsAndRoles from '../hooks/useUserPermissionsAndRoles';

import FieldsGroup from '../utils/FieldsGroup';
import SimpleShowLabeled from '../utils/SimpleShowLabeled';
import hasUserPermissions from '../../utils/hasUserPermissions';

import Datagrid from '../designSystem/react-admin/Datagrid';
import BooleanField from '../designSystem/react-admin/BooleanField';

import resources from '../../constants/resources';

import { useConstantContext } from '../ConstantsContext';

const AccountInvoiceSettings = ({ resource }) => {
  const { constants, choices } = useConstantContext();
  const accountRecord = useRecordContext();
  const authenticationData = usePermissions();
  const isCreditor = useIsCreditor();
  const { t } = useTranslation();
  const { isFinancer } = useUserPermissionsAndRoles();

  const { userActions } = constants;
  const { userAllowedPermissions } = authenticationData.permissions || {};

  const {
    emailLayoutTypesInvoice,
    emailLayoutTypesReminder,
    invoiceLayoutTypes,
    reminderLayoutTypes,
  } = choices;

  const canView = hasUserPermissions(
    userAllowedPermissions,
    userActions.VIEW_INVOICES,
  );

  const isAccountResource = resource === resources.ORDER || resource === resources.LOAN;

  if (
    !canView
    || (resource === resources.ORDER && !isCreditor)
    || !isFinancer
    || !isAccountResource
    || !accountRecord
  ) return null;

  return (
    <FieldsGroup
      title={t('Invoicing settings')}
      actions={(
        <Box display="flex" gap="0.5rem" flexDirection="row">
          <UpdateAccountInvoiceSettingsButton resource={resource} />
        </Box>
      )}
    >
      <Box display="flex" flexDirection="column">
        <BooleanField source="invoicingSettings.shouldSendInvoices" label={t('Send invoice')} looseValue />
        <BooleanField source="invoicingSettings.enableAutomaticDunning" label={t('Dunning flow')} looseValue />
        <Grid container marginTop="1rem">
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <ReferenceField
                label={t('Invoice sender')}
                source="invoicingSettings.displaySenderId"
                reference="entity-v2/reference"
                link={false}
                emptyText="-"
              >
                <TextField source="mainAddress.name" />
              </ReferenceField>
            </SimpleShowLabeled>
          </Grid>
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <TextField source="invoicingSettings.invoiceFee" label={t('Invoice fee')} emptyText="-" />
            </SimpleShowLabeled>
          </Grid>
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <TextField source="invoicingSettings.invoiceAddress.streetAddress" label={t('Street address')} emptyText="-" />
            </SimpleShowLabeled>
          </Grid>
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <TextField source="invoicingSettings.invoiceEmail" label={t('Invoice email')} emptyText="-" />
            </SimpleShowLabeled>
          </Grid>
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <TextField source="invoicingSettings.iban" label="IBAN" emptyText="-" />
            </SimpleShowLabeled>
          </Grid>
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <TextField source="invoicingSettings.invoiceAddress.additionalStreetAddress" label={t('Additional street address')} emptyText="-" />
            </SimpleShowLabeled>
          </Grid>
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <SelectField
                source="invoicingSettings.emailLayout"
                label={t('Email layout')}
                choices={emailLayoutTypesInvoice}
                emptyText="-"
              />
            </SimpleShowLabeled>
          </Grid>
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <TextField source="invoicingSettings.bic" label="BIC" emptyText="-" />
            </SimpleShowLabeled>
          </Grid>
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <TextField source="invoicingSettings.invoiceAddress.town" label={t('Town')} emptyText="-" />
            </SimpleShowLabeled>
          </Grid>
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <SelectField
                source="invoicingSettings.invoiceLayout"
                label={t('Invoice layout')}
                choices={invoiceLayoutTypes}
                emptyText="-"
              />
            </SimpleShowLabeled>
          </Grid>
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <TextField source="invoicingSettings.bankgiro" label={t('Bankgiro')} emptyText="-" />
            </SimpleShowLabeled>
          </Grid>
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <TextField source="invoicingSettings.invoiceAddress.postcode" label={t('Postcode')} emptyText="-" />
            </SimpleShowLabeled>
          </Grid>
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <FunctionField
                render={(record) => {
                  const denunciationText = record?.invoicingSettings?.denunciationText;
                  if (denunciationText) {
                    return denunciationText.length > 25
                      ? `${denunciationText.substring(0, 25)}...`
                      : denunciationText;
                  }
                  return '-';
                }}
                label={t('Denunciation text')}
                emptyText="-"
              />
            </SimpleShowLabeled>
          </Grid>
        </Grid>
      </Box>
      <Grid container marginTop="1rem">
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <TextField source="invoicingSettings.lateInterestRate" label={`${t('Late interest')} (%)`} emptyText="-" />
          </SimpleShowLabeled>
        </Grid>
      </Grid>
      <Box marginTop="2.5rem">
        <SimpleShowLabeled fullWidth>
          <ArrayField label={t('Dunning settings')} source="invoicingSettings.dunningSettings">
            <Datagrid bulkActionButtons={false} rightAlignedColumns={['feeAmount', 'issueDay']}>
              <TextField source="description" label={t('Description')} emptyText="-" />
              <TextField source="feeAmount" label={t('Fee amount')} emptyText="-" />
              <TextField source="issueDay" label={t('Issue day')} emptyText="-" />
              <TextField source="description" label={t('Description')} emptyText="-" />
              <SelectField
                source="emailLayout"
                label={t('Email layout')}
                choices={emailLayoutTypesReminder}
                emptyText="-"
              />
              <SelectField
                source="reminderLayout"
                label={t('Reminder layout')}
                choices={reminderLayoutTypes}
                emptyText="-"
              />
              <TextField source="feeAmount" label="Fee amount" emptyText="-" />
              <TextField source="issueDay" label="Issue day" emptyText="-" />
            </Datagrid>
          </ArrayField>
        </SimpleShowLabeled>
      </Box>
    </FieldsGroup>
  );
};

AccountInvoiceSettings.propTypes = {
  resource: PropTypes.string.isRequired,
};

export default AccountInvoiceSettings;
