import React from 'react';
import moment from 'moment';

import { Loading, useRecordContext } from 'react-admin';
import { startCase, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

import { isDefined } from '../../utils/validators';

import { useConstantContext } from '../ConstantsContext';

import StatusChip, { colors } from '../designSystem/StatusChip';

const DeliveryConfirmationStatusChip = () => {
  const record = useRecordContext();
  const { status, confirmedAt } = record;
  const { constants } = useConstantContext();
  const { t } = useTranslation();
  const { deliveryConfirmationStatuses } = constants;

  if (isEmpty(deliveryConfirmationStatuses)) return <Loading />;
  if (!isDefined(status)) return null;

  let chipColor;
  let chipLabel;
  if (status === deliveryConfirmationStatuses.PENDING) {
    chipColor = colors.orange;
    chipLabel = t('Pending');
  } else if (status === deliveryConfirmationStatuses.CONFIRMED) {
    chipColor = colors.green;
    chipLabel = `Confirmed ${moment(confirmedAt).format('DD MMM YYYY')}`;
  } else if (status === deliveryConfirmationStatuses.CANCELLED) {
    chipColor = colors.gray;
    chipLabel = t('Cancelled');
  } else throw new Error(`Invalid delivery confirmation status ${status}`);

  return <StatusChip label={chipLabel || startCase(status)} chipColor={chipColor} fontSize="10px" />;
};

export default DeliveryConfirmationStatusChip;
