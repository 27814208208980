import React, { useCallback, useState } from 'react';
import {
  required,
  useRecordContext,
} from 'react-admin';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import TextInput from '../designSystem/react-admin/inputs/TextInput';
import SelectInput from '../designSystem/react-admin/inputs/SelectInput';
import InputsGroup from '../designSystem/InputsGroup';
import { useConstantContext } from '../ConstantsContext';
import NumberInput from '../designSystem/react-admin/inputs/NumberInput';

const KycFields = (
) => {
  const record = useRecordContext();
  const { choices } = useConstantContext();
  const { t } = useTranslation();
  const validateRequired = [required()];
  const { setValue } = useFormContext();

  const {
    creditScoreProviders,
    paymentScoreProviders,
  } = choices;

  const [
    creditScoreProviderInput,
    setCreditScoreProviderInput,
  ] = useState(record.creditScoreProvider || '');
  const [
    paymentScoreProviderInput,
    setPaymentScoreProviderInput,
  ] = useState(record.paymentScoreProvider || '');

  const handleCreditScoreProvidersChange = useCallback((e) => {
    setCreditScoreProviderInput(e.target.value);
    if (e.target.value === '') setValue('creditScore', null);
  }, [setValue]);
  const handlePaymentScoreProviderChange = useCallback((e) => {
    setPaymentScoreProviderInput(e.target.value);
    if (e.target.value === '') setValue('paymentScore', null);
  }, [setValue]);

  return (
    <InputsGroup>
      <SelectInput
        label={t('Credit score provider')}
        source="creditScoreProvider"
        choices={creditScoreProviders}
        onChange={handleCreditScoreProvidersChange}
      />
      {creditScoreProviderInput && (
        <TextInput
          label={t('Credit score')}
          source="creditScore"
          validate={validateRequired}
        />
      )}
      <SelectInput
        label={t('Payment score provider')}
        choices={paymentScoreProviders}
        source="paymentScoreProvider"
        onChange={handlePaymentScoreProviderChange}
      />
      {paymentScoreProviderInput && (
        <TextInput
          label={t('Payment score')}
          source="paymentScore"
          validate={validateRequired}
        />
      )}
      <TextInput label={t('Financer score')} source="financerScore" />
      <NumberInput label={t('Expected monthly order volume')} source="expectedVolume" />
    </InputsGroup>
  );
};

export default KycFields;
