import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';
import { useRecordContext } from 'react-admin';

import ModalButton from '../designSystem/ModalButton';

import useCustomRpc from '../hooks/useCustomRpc';

const MarkAsParkedButton = () => {
  const record = useRecordContext();
  const { t } = useTranslation();

  const {
    mutate: markAsParked,
    isLoading,
  } = useCustomRpc({
    path: `account/${record.id}/mark-as-parked`,
    httpMethod: 'PUT',
    shouldRefresh: true,
    errorMessage: t('Could not mark account as parked'),
  });

  const handleClick = useCallback(() => {
    markAsParked();
  }, [markAsParked]);

  if (record.partnerBillingId) return null;

  return (
    <ModalButton
      modalTitle={t('Move billing to be invoiced')}
      openButtonLabel={t('Move billing to be invoiced')}
      onClick={handleClick}
      disabled={isLoading}
      actionLabel={t('Move billing')}
      variant="subAction"
      width="30rem"
    >
      <Typography paragraph>{t('The billing account will be prepared so you can send an invoice or charge it on the clients settlement account. The account will be closed to new transactions and new transactions will open a new billing account.')}</Typography>
      <Typography>{t('This action cannot be reversed.')}</Typography>
    </ModalButton>
  );
};

export default MarkAsParkedButton;
