import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useRecordContext } from 'react-admin';
import { useStatistics } from '../dashboards/StatisticsContext';

import useFeatureFlags from '../hooks/useFeatureFlags';

import FieldsGroup from '../utils/FieldsGroup';

import EntityExposureCharts from './EntityExposureCharts';
import RefreshExposureKPIButton from './RefreshExposureKPIButton';
import TitleH1 from '../designSystem/TitleH1';

const EntityExposureGroup = ({ route }) => {
  const { t } = useTranslation();
  const [featureFlags] = useFeatureFlags();
  const record = useRecordContext();
  const { statisticsData } = useStatistics();

  if (!featureFlags.enableAnalyticsAndRedesign) return null;

  const isDashboard = route === 'merchantDashboard' || route === 'financerDashboard';

  const entityCoreId = isDashboard
    ? statisticsData.entityCoreId
    : record.entityCoreId;

  return (
    <FieldsGroup
      title={isDashboard ? <TitleH1 title={t('Exposure')} /> : t('Exposure')}
      actions={(
        <RefreshExposureKPIButton entityCoreId={entityCoreId} />
      )}
    >
      <EntityExposureCharts route={route} />
    </FieldsGroup>
  );
};

EntityExposureGroup.propTypes = {
  route: PropTypes.string.isRequired,
};

export default EntityExposureGroup;
