import React, { createElement } from 'react';
import {
  AppBar,
  UserMenu,
  MenuItemLink,
  Logout,
  useResourceDefinitions,
  useGetResourceLabel,
  useTheme,
  useUserMenu,
} from 'react-admin';

import LogoutIcon from '@mui/icons-material/Logout';
import DefaultIcon from '@mui/icons-material/ViewList';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import userMenuGroup from '../userMenuGroup';
import CustomResourceMenuItem from '../CustomResourceMenuItem';
import { menuItemLinkStyle } from '../../designSystem/react-admin/menu/MenuItemLink';

import { getEntityNameFromToken, getUserFirstNameFromToken, getUserLastNameFromToken } from '../../../utils/getValueFromToken';
import { useConstantContext } from '../../ConstantsContext';

import useHasUserPermissions from '../../hooks/useHasUserPermissions';

import ChangeLanguageButton from '../../user/ChangeLanguageButton';

const UserMenuItemLink = (props) => {
  // eslint-disable-next-line react/prop-types
  const { to } = props;
  const { onClose } = useUserMenu();
  const { constants } = useConstantContext();
  const { userActions } = constants;

  const canViewUsers = useHasUserPermissions(
    constants,
    userActions.VIEW_USERS,
  );

  const canViewMyCompany = useHasUserPermissions(
    constants,
    userActions.VIEW_MY_COMPANY_INFORMATION,
  );

  if (to === '/manage-users' && !canViewUsers) return null;
  if (to === '/my-company' && !canViewMyCompany) return null;

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <MenuItemLink onClick={onClose} {...props} />;
};

const userMenuItemLinkStyle = (theme) => ({
  ...menuItemLinkStyle({ theme }),
  ':hover': {
    backgroundColor: 'transparent',
    '& .MuiSvgIcon-root': {
      path: {
        fill: theme.palette.secondary.main,
      },
    },
  },
  '& .MuiSvgIcon-root': {
    width: '0.875rem',
    height: '0.875rem',
    color: '#B4AEA6',
  },
});

const NamedAppBar = () => {
  const userFirstName = getUserFirstNameFromToken();
  const userLastName = getUserLastNameFromToken();
  const entityName = getEntityNameFromToken();

  const resourcesDefinitions = useResourceDefinitions();
  const getResourceLabel = useGetResourceLabel();
  const { clientInfo } = useConstantContext();
  const { logoUrl } = clientInfo;

  useTheme('light');

  return (
    <AppBar
      elevation={0}
      sx={{
        height: '4.5rem',
        padding: '0.75em 0',
        position: 'unset',
        '& .RaAppBar-menuButton > *': {
          color: '#B4AEA6',
          fontSize: '1rem',
        },
        '& .RaAppBar-toolbar': {
          paddingLeft: '0.8em',
        },
      }}
      color="inherit"
      container="div"
      userMenu={(
        <UserMenu label="">
          <Box
            width="14.5rem"
            padding="0.75rem 1.25rem"
            sx={(theme) => ({
              '& .RaMenuItemLink-active': {
                backgroundColor: 'transparent!important',
                '& .MuiSvgIcon-root': {
                  path: {
                    fill: theme.palette.secondary.main,
                  },
                },
                fontWeight: 700,
              },
            })}
          >
            <Typography fontSize="12px" fontWeight={500} color="headers">{`${userFirstName} ${userLastName}${entityName ? ` (${entityName})` : ''}`}</Typography>
            {userMenuGroup.map((resourceName) => (
              <UserMenuItemLink
                key={resourceName}
                to={`/${resourceName}`}
                primaryText={getResourceLabel(resourceName, 2)}
                sx={userMenuItemLinkStyle}
                leftIcon={resourcesDefinitions[resourceName]
                  && resourcesDefinitions[resourceName].icon
                  ? createElement(resourcesDefinitions[resourceName].icon)
                  : <DefaultIcon />}
              />
            ))}
            <ChangeLanguageButton />
            <Logout
              sx={userMenuItemLinkStyle}
              icon={<LogoutIcon />}
            />
          </Box>
        </UserMenu>
      )}
      toolbar={<></>}
    >
      <Box
        width="100%"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexDirection={logoUrl ? 'row' : 'row-reverse'}
      >
        {logoUrl && <img style={{ height: '1.8rem' }} src={logoUrl} alt="Company logo" />}
        <CustomResourceMenuItem
          key="notification"
          name="notification"
          hideResourceName
          sx={{
            padding: '0rem',
            ':hover': { backgroundColor: 'transparent' },
            width: 'auto',
            '&.MuiButtonBase-root': {
              color: 'unset',
            },
            '&.RaMenuItemLink-active': {
              backgroundColor: 'transparent',
            },
            '& > .MuiListItemIcon-root': {
              minWidth: 'auto',
            },
          }}
        />
      </Box>
    </AppBar>
  );
};

export default NamedAppBar;
