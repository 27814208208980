import React from 'react';

import {
  Typography,
} from '@mui/material';

import {
  ArrayField,
  DateField,
  FunctionField,
  TextField,
  usePermissions,
  useRecordContext,
  WrapperField,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import CurrencyField from '../utils/CurrencyField';
import InvoiceStatusChip from '../utils/InvoiceStatusChip';

import Datagrid from '../designSystem/react-admin/Datagrid';

import InvoiceExpand from './InvoiceExpand';
import InvoiceSubActions from './InvoiceSubActions';
import useIsCreditor from '../hooks/useIsCreditor';
import hasUserPermissions from '../../utils/hasUserPermissions';
import { useConstantContext } from '../ConstantsContext';

const InvoicesData = () => {
  const record = useRecordContext();
  const isCreditor = useIsCreditor();
  const { constants } = useConstantContext();
  const authenticationData = usePermissions();
  const { t } = useTranslation();

  const { userAllowedPermissions } = authenticationData.permissions || {};

  const canEditInvoices = hasUserPermissions(
    userAllowedPermissions,
    constants.userActions.MANAGE_INVOICES,
  );

  const { currency, accountType } = record;

  return (
    <ArrayField source="invoices" label="">
      <Datagrid
        expand={<InvoiceExpand currency={currency} accountType={accountType} isCreditor={isCreditor} />}
        bulkActionButtons={false}
        variant={accountType === 'billing' ? 'greyBackground' : 'whiteBackground'}
        sx={{ marginBottom: accountType === 'billing' ? '0.5rem' : '0' }}
        rightAlignedColumns={['remainingBalance', 'amount', 'relevantInvoices', 'subActions', 'status']}
      >
        <TextField source="referenceNumber" label={t('Reference number')} />
        <DateField source="issueDate" label={t('Issue date')} emptyText="-" />
        <DateField source="expiryDate" label={t('Due date')} emptyText="-" />
        <DateField source="sentAt" label={t('Sent at')} emptyText="-" />
        <CurrencyField source="amount" currencySource="currency" label={t('Amount')} />
        <CurrencyField source="remainingBalance" currencySource="currency" label={t('Remaining balance')} />
        <WrapperField label={t('Reminders / credit notes')} source="relevantInvoices">
          <FunctionField
            render={(invoice) => <Typography fontSize="14px">{invoice.dunning.length + invoice.creditInvoices.length}</Typography>}
          />
        </WrapperField>
        <WrapperField label={t('Status')} source="status">
          <InvoiceStatusChip />
        </WrapperField>
        {canEditInvoices && (
          <WrapperField label={false} source="subActions">
            <InvoiceSubActions accountId={record ? record.id : ''} isCreditor={isCreditor} accountRecord={record} />
          </WrapperField>
        )}
      </Datagrid>
    </ArrayField>
  );
};

export default InvoicesData;
