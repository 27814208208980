import React from 'react';
import {
  ArrayField,
  TextField,
  FunctionField,
  DateField,
  WrapperField,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import Datagrid from '../designSystem/react-admin/Datagrid';

import FieldsGroup from '../utils/FieldsGroup';
import renderTransactionChip from '../utils/renderTransactionChip';
import CurrencyField from '../utils/CurrencyField';
import MarkAsUnidentifiedButton from '../utils/MarkAsUnidentifiedButton';

const TransactionsGroup = () => {
  const { t } = useTranslation();
  return (
    <FieldsGroup title={t('Transactions')}>
      <ArrayField source="transactions" fullWidth>
        <Datagrid bulkActionButtons={false} rightAlignedColumns={['transactionDetails\\.deliveredQuantity', 'amount', 'actions']} cellMaxWidth="unset">
          <FunctionField
            label={t('Type')}
            render={renderTransactionChip}
          />
          <TextField source="description" />
          <DateField source="date" label={t('Date')} emptyText="-" />
          <TextField source="transactionDetails.unit" label={t('Unit')} emptyText="-" />
          <TextField source="transactionDetails.deliveredQuantity" label={t('Delivered quantity')} emptyText="-" />
          <CurrencyField source="amount" currencySource="currency" label={t('Amount')} />
          <WrapperField label={false} source="actions">
            <MarkAsUnidentifiedButton />
          </WrapperField>
        </Datagrid>
      </ArrayField>
    </FieldsGroup>
  );
};

export default TransactionsGroup;
