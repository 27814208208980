import React from 'react';
import {
  ArrayField,
  DateField,
  FunctionField,
  NumberField,
  useRecordContext,
  WrapperField,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import CurrencyField from '../utils/CurrencyField';
import renderTransactionChip from '../utils/renderTransactionChip';
import SimpleShowLabeled from '../utils/SimpleShowLabeled';

import Datagrid from '../designSystem/react-admin/Datagrid';
import DescriptionField from '../designSystem/react-admin/DescriptionField';
import TitleH4 from '../designSystem/TitleH4';

import OrderLink from './OrderLink';

import InvoicesData from '../invoice/InvoicesData';

import { useConstantContext } from '../ConstantsContext';
import PaginatedArrayField from '../designSystem/react-admin/PaginantedArrayField';

const AccountBreakdown = () => {
  const {
    billingsInOrderCurrency,
    currency,
    invoicingSettings,
    accountStatus,
  } = useRecordContext();
  const { constants } = useConstantContext();
  const { accountStatuses } = constants;
  const { t } = useTranslation();

  const shouldDisplayBreakDown = billingsInOrderCurrency.length > 1
    || billingsInOrderCurrency[0].orderCurrency !== currency;

  const invoicedAccount = accountStatus === accountStatuses.INVOICED
  || accountStatus === accountStatuses.CLOSED;

  const shouldDisplayTermsOfPayment = invoicingSettings
  && invoicingSettings.shouldSendInvoices
  && invoicedAccount;

  return (
    <>
      {shouldDisplayTermsOfPayment
        && (
          <>
            <TitleH4 title={t('Invoices')} margin="1.2rem 0 -0.3rem" />
            <InvoicesData />
          </>
        )}
      {shouldDisplayBreakDown
        && (
          <>
            <TitleH4 title={t('Currency breakdown')} margin="1.2rem 0 -0.3rem" />
            <SimpleShowLabeled>
              <ArrayField source="billingsInOrderCurrency" label="">
                <Datagrid
                  variant="greyBackground"
                  bulkActionButtons={false}
                  sx={{ marginBottom: '0.5rem' }}
                  rightAlignedColumns={['convertedSum', 'amountInFinancingCurrency']}
                >
                  <CurrencyField source="convertedSum" currencySource="orderCurrency" label={t('Order currency')} />
                  <FunctionField
                    label={t('Financing currency')}
                    render={(record) => <CurrencyField customRecord={{ ...record, currency }} source="originalSum" currencySource="currency" label={t('Financing currency')} />}
                  />
                </Datagrid>
              </ArrayField>
            </SimpleShowLabeled>
          </>
        )}
      <TitleH4 title={t('Transactions')} margin="1.2rem 0 -0.3rem" />
      <SimpleShowLabeled fullWidth>
        <PaginatedArrayField source="transactions" label="" initialPerPage={100}>
          <Datagrid variant="blueBackground" bulkActionButtons={false} rightAlignedColumns={['amount', 'orderNumber', 'amountOrderCurrency', 'origin\\.fxRate']} cellMaxWidth="unset">
            <FunctionField
              label={t('Type')}
              render={renderTransactionChip}
            />
            <DescriptionField source="description" />
            <DateField source="date" label={t('Date')} emptyText="-" />
            <CurrencyField source="amount" currencySource="currency" label={t('Amount')} coefficient={-1} />
            {shouldDisplayBreakDown
              && (
                <WrapperField label={t('Order currency amount')} source="amountOrderCurrency">
                  <FunctionField
                    render={(record) => record && (
                    <CurrencyField
                      source="amountInOrderCurrency"
                      currencySource="originCurrency"
                      label={t('Amount in order currency')}
                      coefficient={-1}
                      customRecord={{
                        amountInOrderCurrency: record.amount / (record.origin.fxRate || 1),
                        originCurrency: record.origin.currency,
                      }}
                    />
                    )}
                  />
                </WrapperField>
              )}
            {shouldDisplayBreakDown
              && (
                <NumberField source="origin.fxRate" label={t('FX rate')} />
              )}
            <WrapperField label={t('Order number')} source="orderNumber">
              <OrderLink />
            </WrapperField>
          </Datagrid>
        </PaginatedArrayField>
      </SimpleShowLabeled>
    </>
  );
};

export default AccountBreakdown;
