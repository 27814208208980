import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import {
  ArrayInput,
  required,
  useRecordContext,
  ReferenceInput,
} from 'react-admin';
import { t } from 'i18next';

import { get } from 'lodash';

import useCustomRpc from '../hooks/useCustomRpc';

import renderReference from '../../utils/renderReference';

import BooleanInput from '../designSystem/react-admin/inputs/BooleanInput';
import { EditModalButton } from '../designSystem/ModalButton';
import SimpleFormIterator from '../designSystem/react-admin/SimpleFormIterator';
import InputsGroup from '../designSystem/InputsGroup';
import NumberInput from '../designSystem/react-admin/inputs/NumberInput';
import SelectInput from '../designSystem/react-admin/inputs/SelectInput';
import PercentageInput from '../designSystem/react-admin/inputs/PercentageInput';
import AutocompleteInput from '../designSystem/react-admin/inputs/AutocompleteInput';

import {
  bicValidation,
  emailValidation,
  ibanValidation,
  percentageValidation,
  positiveNumberValidation,
} from '../../utils/validationErrors';
import TextInput from '../designSystem/react-admin/inputs/TextInput';

import { useConstantContext } from '../ConstantsContext';
import useHasUserPermissions from '../hooks/useHasUserPermissions';

const validateRequired = [required()];

export const invoicingSettingsResources = {
  ORDER: 'order',
  MERCHANT: 'merchant',
  MY_COMPANY: 'myCompany',
  LOAN: 'loan',
};

const mapResource = (
  id,
  entityCoreId,
  entityOwnerId,
) => ({
  [invoicingSettingsResources.MERCHANT]: {
    path: `entity-v2/invoicing-settings/${id}`,
    errorMessage: t('Could not update the invoicing settings for the merchant'),
    invoiceSenderFilter: [entityCoreId, entityOwnerId],
  },
  [invoicingSettingsResources.MY_COMPANY]: {
    path: 'my-company/invoicing-settings',
    errorMessage: t('Could not update the invoicing settings in the my company page'),
    invoiceSenderFilter: id,
  },
});

const CustomInput = ({
  source,
  InputComponent,
  disabledCondition,
  ...props
}) => {
  const splitedSource = source.split('.');

  splitedSource.pop();

  const sourcePrefix = splitedSource.join('.');

  const record = useRecordContext();

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <InputComponent source={source} {...props} disabled={disabledCondition(get(record, sourcePrefix))} />
  );
};

CustomInput.propTypes = {
  source: PropTypes.string.isRequired,
  InputComponent: PropTypes.elementType.isRequired,
  disabledCondition: PropTypes.func,
};

CustomInput.defaultProps = {
  disabledCondition: () => false,
};

const UpdateEntityInvoiceSettingsButton = ({ resource }) => {
  const record = useRecordContext();
  const {
    id,
    entityCoreId,
    entityOwnerId,
  } = record;

  const { constants, choices } = useConstantContext();

  const {
    path,
    errorMessage,
    invoiceSenderFilter,
  } = mapResource(
    id,
    entityCoreId,
    entityOwnerId,
  )[resource];

  const {
    emailLayoutTypesInvoice,
    emailLayoutTypesReminder,
    invoiceLayoutTypes,
    reminderLayoutTypes,
    currencies,
  } = choices;

  const {
    mutate: updateInvoicingSettings,
    isLoading,
  } = useCustomRpc({
    path,
    httpMethod: 'PUT',
    shouldRefresh: true,
    errorMessage,
  });

  const onSubmit = useCallback((formData) => {
    updateInvoicingSettings(formData);
    return true;
  }, [
    updateInvoicingSettings,
  ]);

  const canEdit = useHasUserPermissions(
    constants,
    constants.userActions.MANAGE_INVOICES,
  );

  if (!canEdit
  ) return null;

  const disabledCondition = (recordContext) => recordContext && !!recordContext.reminderId;

  return (
    <EditModalButton
      modalTitle={t('Update invoicing settings')}
      onClick={onSubmit}
      disabled={isLoading}
      fullWidth
      withForm
      formDefaultValue={record}
      width="47.5rem"
    >
      <BooleanInput
        source="invoicingSettings.shouldSendInvoices"
        label={t('Should send invoices')}
        helperText={t('Automatically send invoice to the customer. Invoices will be sent by email to the invoice email address in the customer details. For factoring, the invoice will be sent after the financing application has been approved, reminders and credit notes are send based on the schedule or manual decisions.')}
      />
      <InputsGroup layout="column">
        <ReferenceInput
          source="invoicingSettings.displaySenderId"
          reference="entity-v2/reference"
          filter={{ id: invoiceSenderFilter }}
        >
          <AutocompleteInput
            label={t('Invoice sender')}
            validate={validateRequired}
            optionText={renderReference}
            suggestionLimit={20}
          />
        </ReferenceInput>
        <TextInput source="invoicingSettings.invoiceAddress.streetAddress" label={t('Street address')} />
        <TextInput source="invoicingSettings.invoiceAddress.additionalStreetAddress" label={t('Additional street address')} />
        <TextInput source="invoicingSettings.invoiceAddress.town" label={t('Town')} />
        <TextInput source="invoicingSettings.invoiceAddress.postcode" label={t('Postcode')} />
        <TextInput source="invoicingSettings.invoiceEmail" label={t('Invoice email')} type="email" validate={[emailValidation]} />
        <SelectInput
          label={t('Email layout')}
          source="invoicingSettings.emailLayout"
          choices={emailLayoutTypesInvoice}
          defaultValue={emailLayoutTypesInvoice[0].id}
          validate={validateRequired}
        />
        <SelectInput
          label={t('Invoice layout')}
          source="invoicingSettings.invoiceLayout"
          choices={invoiceLayoutTypes}
          defaultValue={invoiceLayoutTypes[0].id}
          validate={validateRequired}
        />
      </InputsGroup>
      <InputsGroup title={t('Currency settings')}>
        <ArrayInput
          source="invoicingSettings.currencySettings"
          label={false}
          disabled={!canEdit}
        >
          <SimpleFormIterator addButtonLabel={t('Add new currency settings')} itemLabel={t('Currency settings')} disabledCondition={disabledCondition}>
            <InputsGroup layout="column">
              <SelectInput source="currency" label={t('Currency')} choices={currencies} validate={validateRequired} />
              <PercentageInput source="lateInterestRate" label={`${t('Late interest')} (%)`} validate={[percentageValidation]} />
              <NumberInput
                label={t('Invoice fee')}
                source="invoiceFee"
                validate={[positiveNumberValidation]}
              />
              <TextInput source="bic" label="BIC" validate={[bicValidation]} />
              <TextInput source="iban" label="IBAN" validate={[ibanValidation]} />
              <TextInput source="bankgiro" label={t('Bankgiro')} />
            </InputsGroup>
            <InputsGroup>
              <TextInput
                source="denunciationText"
                label={t('Denunciation text')}
                placeholder={t('Write your denunciation text here')}
                multiline
                emptyText="-"
              />
            </InputsGroup>
            <InputsGroup title={t('Dunning')}>
              <ArrayInput
                source="dunningSettings"
                label={false}
                disabled={!canEdit}
              >
                <SimpleFormIterator addButtonLabel={t('Add new dunning settings')} itemLabel={t('Dunning settings')} disabledCondition={disabledCondition}>
                  <InputsGroup layout="column">
                    <CustomInput
                      label={t('Fee amount')}
                      source="feeAmount"
                      validate={[positiveNumberValidation, ...validateRequired]}
                      disabledCondition={disabledCondition}
                      InputComponent={NumberInput}
                    />
                    <CustomInput
                      label={t('Issue day')}
                      source="issueDay"
                      validate={[positiveNumberValidation, ...validateRequired]}
                      disabledCondition={disabledCondition}
                      InputComponent={NumberInput}
                    />
                    <CustomInput
                      label={t('Description')}
                      source="description"
                      validate={validateRequired}
                      disabledCondition={disabledCondition}
                      InputComponent={TextInput}
                    />
                    <CustomInput
                      label={t('Email layout')}
                      source="emailLayout"
                      choices={emailLayoutTypesReminder}
                      disabledCondition={disabledCondition}
                      InputComponent={SelectInput}
                      validate={validateRequired}
                    />
                    <CustomInput
                      label={t('Reminder layout')}
                      source="reminderLayout"
                      choices={reminderLayoutTypes}
                      disabledCondition={disabledCondition}
                      InputComponent={SelectInput}
                      validate={validateRequired}
                    />
                  </InputsGroup>
                </SimpleFormIterator>
              </ArrayInput>
            </InputsGroup>
          </SimpleFormIterator>
        </ArrayInput>
      </InputsGroup>
    </EditModalButton>
  );
};

UpdateEntityInvoiceSettingsButton.propTypes = {
  resource: PropTypes.string.isRequired,
};

export default UpdateEntityInvoiceSettingsButton;
