import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useRecordContext } from 'react-admin';
import { useConstantContext } from '../ConstantsContext';

import useCustomRpc from '../hooks/useCustomRpc';
import useUserPermissionsAndRoles from '../hooks/useUserPermissionsAndRoles';

import ModalButton from '../designSystem/ModalButton';

import { isValidArray } from '../../utils/validators';

const MarkAsPaidButton = ({ isCreditor, accountId, invoiceId }) => {
  const { invoices, isPaid, status } = useRecordContext();
  const { t } = useTranslation();
  const { isMerchant } = useUserPermissionsAndRoles();
  const { constants } = useConstantContext();
  const { invoiceStatuses } = constants;

  const path = `account/${accountId}/mark-as-paid-v2`;

  const {
    mutate: markAsPaid,
    isLoading,
  } = useCustomRpc({
    path,
    httpMethod: 'PUT',
    shouldRefresh: true,
    errorMessage: t('Could not mark account as paid'),
  });

  const handleClick = useCallback(() => {
    markAsPaid({
      invoiceId,
    });
    return true;
  }, [markAsPaid, invoiceId]);

  const disableMarkAsPaidForBillingAccount = !invoiceId && isValidArray(invoices);
  if (disableMarkAsPaidForBillingAccount) return null;

  if (isPaid
    || status === invoiceStatuses.PAID
    || (isMerchant && !isCreditor)) return null;

  return (
    <ModalButton
      modalTitle={t('Mark as paid')}
      openButtonLabel={t('Mark as paid')}
      onClick={handleClick}
      disabled={isLoading}
      actionLabel={t('Continue')}
      variant="subAction"
      width="30rem"
    >
      <Typography paragraph>{t('The invoice will be marked as paid and a corresponding payment will be added.')}</Typography>
      <Typography>{t('This action cannot be reverted.')}</Typography>
    </ModalButton>
  );
};

MarkAsPaidButton.propTypes = {
  isCreditor: PropTypes.bool.isRequired,
  accountId: PropTypes.string.isRequired,
  invoiceId: PropTypes.string,
};

MarkAsPaidButton.defaultProps = {
  invoiceId: undefined,
};

export const MarkInvoiceAsPaid = ({ isCreditor, invoiceId, accountId }) => <MarkAsPaidButton isCreditor={isCreditor} invoiceId={invoiceId} accountId={accountId} />;

MarkInvoiceAsPaid.propTypes = {
  isCreditor: PropTypes.bool.isRequired,
  invoiceId: PropTypes.string.isRequired,
  accountId: PropTypes.string.isRequired,
};

export const MarkAccountAsPaid = ({ accountId }) => {
  const record = useRecordContext();

  if (record.partnerBillingId) return null;

  return (
    <MarkAsPaidButton accountId={accountId} />
  );
};

MarkAccountAsPaid.propTypes = {
  accountId: PropTypes.string.isRequired,
};

export default MarkAsPaidButton;
