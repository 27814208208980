import PropTypes from 'prop-types';
import React from 'react';
import {
  ReferenceField,
  SimpleShowLayout,
  TextField,
  useRecordContext,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';

import SettlementAccountBreakdown from '../entityv2/SettlementAccountBreakdown';
import DownloadAccountBreakdownButton from '../entityv2/DownloadAccountBreakdownButton';
import DownloadOutboundPaymentButton from '../payment/DownloadOutboundPaymentButton';

import CurrencyField from '../utils/CurrencyField';
import FieldsGroup from '../utils/FieldsGroup';
import SimpleShowLabeled from '../utils/SimpleShowLabeled';

import TitleH1 from '../designSystem/TitleH1';
import Show from '../designSystem/react-admin/Show';

import FinancerName from '../utils/FinancerName';

const MerchantName = ({ isBreadcrumbsTitle }) => {
  const { mainAddress } = useRecordContext();
  const { t } = useTranslation();

  return isBreadcrumbsTitle ? mainAddress.name : <TitleH1 title={`${t('Settlement account')}: ${mainAddress.name}`} />;
};
MerchantName.propTypes = {
  isBreadcrumbsTitle: PropTypes.bool,
};
MerchantName.defaultProps = {
  isBreadcrumbsTitle: false,
};

export const SettlementTitle = ({ isBreadcrumbsTitle }) => {
  const { t } = useTranslation();
  return (
    <ReferenceField
      label={t('Merchant')}
      source="debtorId"
      reference="entity-v2/reference"
      link={false}
    >
      <MerchantName isBreadcrumbsTitle={isBreadcrumbsTitle} />
    </ReferenceField>
  );
};
SettlementTitle.propTypes = MerchantName.propTypes;
SettlementTitle.defaultProps = MerchantName.defaultProps;

const SettlementAccountShow = () => {
  const { t } = useTranslation();
  return (
    <Show
      title={<SettlementTitle />}
      appLocation="home.settlement-account.show"
    >
      <SimpleShowLayout>
        <FieldsGroup
          actions={(
            <Box
              display="flex"
              flexDirection="row"
              flexWrap="wrap"
              gap="1rem"
              alignSelf="flex-end"
            >
              <DownloadAccountBreakdownButton
                variant="contained"
                size="small"
                color="primary"
                sx={{ alignSelf: 'center' }}
              />
              <DownloadOutboundPaymentButton
                variant="contained"
                size="small"
                color="primary"
              />
            </Box>
          )}
        >
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            width="100%"
            gap="1rem"
            alignSelf="flex-start"
          >
            <SimpleShowLabeled>
              <FinancerName label={t('Financer')} />
            </SimpleShowLabeled>
            <SimpleShowLabeled>
              <CurrencyField source="balance" currencySource="currency" label={t('Settlement balance')} />
            </SimpleShowLabeled>
            <SimpleShowLabeled>
              <TextField source="paymentReference" label={t('Payment reference')} />
            </SimpleShowLabeled>
          </Box>
          <SettlementAccountBreakdown />
        </FieldsGroup>
      </SimpleShowLayout>
    </Show>
  );
};

export default SettlementAccountShow;
