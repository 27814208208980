import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

import { useRecordContext } from 'react-admin';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { OverviewKpi } from '../dashboards/KpiContainer';
import NoDataContent from '../dashboards/NoDataContent';
import { useStatistics } from '../dashboards/StatisticsContext';
import DonutChart from '../charts/DonutChart';
import ChartContainer from '../charts/ChartContainer';

import roundNumber from '../../utils/roundNumber';
import useUserPermissionsAndRoles from '../hooks/useUserPermissionsAndRoles';

const gridItemStyle = {
  maxWidth: {
    xl: '20rem',
  },
  marginRight: {
    xl: '4rem',
    lg: '2rem',
  },
};

const financialCategories = {
  TOTAL: 'Total',
  FACTORING: 'Factoring',
  LOANS: 'Loans',
};

const useGetFinancialCategoryLabel = () => {
  const { t } = useTranslation();
  return ({
    [financialCategories.TOTAL]: t('Total'),
    [financialCategories.FACTORING]: t('Factoring'),
    [financialCategories.LOANS]: t('Loans'),
  });
};

const FinancialCategoryContext = createContext({
  financialCategory: '',
  setFinancialCategory: () => {},
});

const FinancialCategorySelection = () => {
  const exposureLabels = useGetFinancialCategoryLabel();
  const { financialCategory, setFinancialCategory } = useContext(FinancialCategoryContext);
  const handleClick = useCallback((e) => {
    setFinancialCategory(e.target.id);
  }, [setFinancialCategory]);
  const { hasLoan } = useUserPermissionsAndRoles();

  if (!hasLoan) return null;

  return (
    <Box display="flex" flexDirection="row" gap="0.5rem" flexWrap="wrap">
      {Object.entries(exposureLabels).map(([id, label]) => (
        <Button
          id={id}
          key={id}
          variant={(financialCategory === id) ? 'contained' : 'outlined'}
          onClick={handleClick}
          color={(financialCategory === id) ? 'secondary' : 'primary'}
          sx={{ maxHeight: '1.25rem', alignSelf: 'center' }}
        >
          {label}
        </Button>
      ))}
    </Box>
  );
};

const useRoutedRecord = (route) => {
  const record = useRecordContext();
  const { statisticsData } = useStatistics();

  let kpis;
  let currency;
  if (route === 'financerDashboard' || route === 'merchantDashboard') {
    currency = statisticsData.currency;
    kpis = statisticsData.kpis;
  } else if (record.kpis) {
    kpis = record.kpis;
    currency = record.kpis.currency;
  }

  return {
    kpis,
    currency,
  };
};

const EntityExposureKpis = ({ route }) => {
  const { t } = useTranslation();
  const { financialCategory } = useContext(FinancialCategoryContext);
  const { kpis, currency } = useRoutedRecord(route);
  const { hasLoan } = useUserPermissionsAndRoles();

  if (isEmpty(kpis)) return <NoDataContent />;

  const displayMerchantExposureCharts = (route === 'merchant' || route === 'merchantDashboard');

  const {
    orderOutstandingInvoicedAmount,
    orderOutstandingFinancedAmount,
    orderOverdueAmount,
    orderTotalCount,
    loanOutstandingFinancedAmount,
    loanOverdueAmount,
    loanTotalCount,
    totalOutstandingFinanced,
    totalOverdue,
    totalOverdueAmountRatio,
    orderOverdueAmountRatio,
    loanOverdueAmountRatio,
    factoringReserveAmount,
  } = kpis;

  if (financialCategory === financialCategories.FACTORING) {
    return (
      <Grid container>
        <Grid item lg={3} md={6}>
          <OverviewKpi
            value={orderOutstandingInvoicedAmount}
            label={t('Outstanding invoiced')}
            currency={currency}
            subValue={orderTotalCount}
            subLabel={t('orders sent')}
          />
        </Grid>
        <Grid item lg={3} md={6}>
          <OverviewKpi
            value={orderOutstandingFinancedAmount}
            label={t('Outstanding financed')}
            currency={currency}
            subValue={orderTotalCount}
            subLabel={t('orders financed')}
          />
        </Grid>
        <Grid item lg={3} md={6}>
          <OverviewKpi
            value={orderOverdueAmount}
            label={t('Overdue amount')}
            currency={currency}
            subValue={orderOverdueAmountRatio}
            subLabel={t('of outstanding financing')}
            isRatio
          />
        </Grid>
        <Grid item lg={3} md={6}>
          <OverviewKpi
            value={factoringReserveAmount}
            label={t('Factoring reserve')}
            currency={currency}
          />
        </Grid>
        <Grid container spacing={4}>
          {!hasLoan && displayMerchantExposureCharts && <TotalMerchantExposureDonutCharts route={route} />}
          {!hasLoan && route === 'customer' && <TotalCustomerExposureDonutCharts />}
          {!hasLoan && route === 'financerDashboard' && <FinancerDashboardDonutCharts />}
          <FactoringExposureDonutCharts route={route} />
        </Grid>
      </Grid>
    );
  } if (financialCategory === financialCategories.LOANS) {
    return (
      <Grid container>
        <Grid item lg={3} md={6}>
          <OverviewKpi
            value={loanOutstandingFinancedAmount}
            label={t('Outstanding financed')}
            currency={currency}
            subValue={loanTotalCount}
            subLabel={t('loans')}
          />
        </Grid>
        <Grid item lg={3} md={6}>
          <OverviewKpi
            value={loanOverdueAmount}
            label={t('Overdue amount')}
            currency={currency}
            subValue={loanOverdueAmountRatio}
            subLabel={t('of outstanding financing')}
            isRatio
          />
        </Grid>
        <LoansExposureDonutCharts route={route} />
      </Grid>
    );
  }
  return (
    <Grid container>
      <Grid item lg={3} md={6}>
        <OverviewKpi
          value={totalOutstandingFinanced}
          label={t('Outstanding financed')}
          currency={currency}
          subValue={orderTotalCount}
          subLabel={t('orders')}
          secondSubValue={loanTotalCount}
          secondSubLabel={t('loans')}
        />
      </Grid>
      <Grid item lg={3} md={6}>
        <OverviewKpi
          value={totalOverdue}
          label={t('Overdue amount')}
          currency={currency}
          subValue={totalOverdueAmountRatio}
          subLabel={t('of outstanding financing')}
          isRatio
        />
      </Grid>
      <Grid container spacing={4}>
        {displayMerchantExposureCharts && <TotalMerchantExposureDonutCharts route={route} />}
        {route === 'customer' && <TotalCustomerExposureDonutCharts />}
        {route === 'financerDashboard' && <FinancerDashboardDonutCharts />}
      </Grid>
    </Grid>
  );
};

EntityExposureKpis.propTypes = {
  route: PropTypes.string.isRequired,
};

const TotalMerchantExposureDonutCharts = ({ route }) => {
  const { t } = useTranslation();
  const { kpis } = useRoutedRecord(route);

  const {
    usedFinancingLimit,
    financingLimit,
    utilisedCreditLimit = {},
  } = kpis;

  const financingLimitLabels = [t('utilised').toUpperCase(), t('available').toUpperCase()];
  const financingLimitSerie = [
    roundNumber(usedFinancingLimit),
    roundNumber(financingLimit - usedFinancingLimit),
  ];

  const utilisedCreditLimitLabels = [
    t('low: 0-60%').toUpperCase(),
    t('medium: 61-90%').toUpperCase(),
    t('high: >90%').toUpperCase(),
  ];

  return (
    <>
      <Grid item lg={3} md={6} sx={{ ...gridItemStyle }}>
        <ChartContainer>
          <DonutChart
            serie={financingLimitSerie}
            labels={financingLimitLabels}
            totalLabel={t('Total limit')}
            colours={['#204A89', '#74C6AD']}
            title={t('Financing limit')}
          />
        </ChartContainer>
      </Grid>
      <Grid item lg={3} md={6} sx={{ ...gridItemStyle }}>
        <ChartContainer>
          <DonutChart
            serie={[
              utilisedCreditLimit.low,
              utilisedCreditLimit.medium,
              utilisedCreditLimit.high,
            ]}
            labels={utilisedCreditLimitLabels}
            totalLabel={t('Customers')}
            colours={['#74C6AD', '#204A89', '#FF6961']}
            title={t('Customer credit limits')}
          />
        </ChartContainer>
      </Grid>
    </>
  );
};

TotalMerchantExposureDonutCharts.propTypes = {
  route: PropTypes.string.isRequired,
};

const TotalCustomerExposureDonutCharts = () => {
  const { t } = useTranslation();
  const record = useRecordContext();

  const {
    availableCreditLimitAmount,
    utilisedCreditLimitAmount,
    availableInsuranceAmount,
    utilisedInsuranceAmount,
  } = record.kpis;

  const limitLabels = [t('utilised').toUpperCase(), t('available').toUpperCase()];

  const creditLimitSerie = [
    roundNumber(utilisedCreditLimitAmount),
    roundNumber(availableCreditLimitAmount),
  ];

  const insuranceLimitSerie = [
    roundNumber(utilisedInsuranceAmount),
    roundNumber(availableInsuranceAmount),
  ];

  return (
    <>
      <Grid item lg={3} md={6} sx={{ ...gridItemStyle }}>
        <ChartContainer>
          <DonutChart
            serie={creditLimitSerie}
            labels={limitLabels}
            totalLabel={t('Total limit')}
            colours={['#204A89', '#74C6AD']}
            title={t('Credit limit')}
          />
        </ChartContainer>
      </Grid>
      <Grid item lg={3} md={6} sx={{ ...gridItemStyle }}>
        <ChartContainer>
          <DonutChart
            serie={insuranceLimitSerie}
            labels={limitLabels}
            totalLabel={t('Total limit')}
            colours={['#204A89', '#74C6AD']}
            title={t('Insurance limit')}
          />
        </ChartContainer>
      </Grid>
    </>
  );
};

const FactoringExposureDonutCharts = ({ route }) => {
  const { t } = useTranslation();
  const { kpis } = useRoutedRecord(route);

  const {
    orderInvoiceVolume,
  } = kpis;

  const invoicesLabels = [
    t('not yet due').toUpperCase(),
    t('overdue 1-30 days').toUpperCase(),
    t('overdue 31-60 days').toUpperCase(),
    t('overdue 61-90 days').toUpperCase(),
    t('overdue 90+ days').toUpperCase(),
  ];

  const invoicesSerie = [
    orderInvoiceVolume.notYetOverdue,
    orderInvoiceVolume.overdueLess30,
    orderInvoiceVolume.overdueLess60,
    orderInvoiceVolume.overdueLess90,
    orderInvoiceVolume.overdueMore91,
  ];

  return (
    <Grid item lg={3} md={6} sx={{ ...gridItemStyle }}>
      <ChartContainer>
        <DonutChart
          serie={invoicesSerie}
          labels={invoicesLabels}
          totalLabel={t('Orders')}
          colours={['#74C6AD', '#F2D776', '#F3AE28', '#F93838', '#717171']}
          title={t('Orders')}
        />
      </ChartContainer>
    </Grid>
  );
};

FactoringExposureDonutCharts.propTypes = {
  route: PropTypes.string.isRequired,
};

const LoansExposureDonutCharts = ({ route }) => {
  const { t } = useTranslation();
  const { kpis } = useRoutedRecord(route);

  const {
    loanStatusCount,
  } = kpis;

  const loanOnTimeLabels = [t('on-time').toUpperCase(), t('late').toUpperCase()];

  const loanOnTimeSerie = [
    loanStatusCount.onTimeCount,
    loanStatusCount.lateCount,
  ];

  return (
    <Grid container spacing={4}>
      <Grid item lg={3} md={6} sx={{ ...gridItemStyle }}>
        <ChartContainer>
          <DonutChart
            serie={loanOnTimeSerie}
            labels={loanOnTimeLabels}
            totalLabel={t('Loans')}
            colours={['#74C6AD', '#F93838']}
            title={t('Loans on-time')}
          />
        </ChartContainer>
      </Grid>
    </Grid>
  );
};

LoansExposureDonutCharts.propTypes = {
  route: PropTypes.string.isRequired,
};

const FinancerDashboardDonutCharts = () => {
  const { t } = useTranslation();
  const { statisticsData } = useStatistics();

  const {
    utilisedCreditLimit,
    financingLimitVolume,
  } = statisticsData.kpis;

  const limitLabels = [
    t('low: 0-60%').toUpperCase(),
    t('medium: 61-90%').toUpperCase(),
    t('high: >90%').toUpperCase(),
  ];

  const utilisedCreditLimitSerie = [
    utilisedCreditLimit.low,
    utilisedCreditLimit.medium,
    utilisedCreditLimit.high,
  ];

  const financingLimitSerie = [
    financingLimitVolume.low,
    financingLimitVolume.medium,
    financingLimitVolume.high,
  ];

  return (
    <>
      <Grid item lg={3} md={6} sx={{ ...gridItemStyle }}>
        <ChartContainer>
          <DonutChart
            serie={utilisedCreditLimitSerie}
            labels={limitLabels}
            totalLabel={t('Customers')}
            colours={['#74C6AD', '#204A89', '#FF6961']}
            title={t('Customer credit limits')}
          />
        </ChartContainer>
      </Grid>
      <Grid item lg={3} md={6} sx={{ ...gridItemStyle }}>
        <ChartContainer>
          <DonutChart
            serie={financingLimitSerie}
            labels={limitLabels}
            totalLabel={t('Merchants')}
            colours={['#74C6AD', '#204A89', '#FF6961']}
            title={t('Merchant financing limits')}
          />
        </ChartContainer>
      </Grid>
    </>
  );
};

const EntityExposureCharts = ({ route }) => {
  const { hasLoan } = useUserPermissionsAndRoles();
  const [financialCategory, setFinancialCategory] = useState(hasLoan
    ? financialCategories.TOTAL
    : financialCategories.FACTORING);
  const value = useMemo(
    () => ({ financialCategory, setFinancialCategory }),
    [financialCategory],
  );

  return (
    <Box marginTop="1rem">
      <FinancialCategoryContext.Provider value={value}>
        <FinancialCategorySelection />
        <EntityExposureKpis route={route} />
      </FinancialCategoryContext.Provider>
    </Box>
  );
};

EntityExposureCharts.propTypes = {
  route: PropTypes.string.isRequired,
};

export default EntityExposureCharts;
