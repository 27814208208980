import React from 'react';

import {
  FormTab,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';

import CoreInfoGroup from '../../CoreInfoGroup';
import CompanyDetails from '../../company-details/CompanyDetails';

import FieldsGroup from '../../../utils/FieldsGroup';

import UpdateCompanySettings from '../../../myCompany/UpdateMyCompanySettings';
import EntityInvoiceSettings from '../../EntityInvoiceSettings';

import useUserPermissionsAndRoles from '../../../hooks/useUserPermissionsAndRoles';

import FinancingInfoGroup from '../../FinancingInfoGroup';
import { useConstantContext } from '../../../ConstantsContext';
import useHasUserPermissions from '../../../hooks/useHasUserPermissions';

import resources from '../../../../constants/resources';

import BooleanField from '../../../designSystem/react-admin/BooleanField';

const SummaryTab = (props) => {
  const { isMerchant, hasFactoring } = useUserPermissionsAndRoles();
  const { constants } = useConstantContext();
  const { userActions } = constants;
  const { t } = useTranslation();

  const canEdit = useHasUserPermissions(
    constants,
    userActions.EDIT_MY_COMPANY_INFORMATION,
  );

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormTab label={t('Summary')} {...props}>
      <CoreInfoGroup />
      <CompanyDetails
        viewUserAction={userActions.VIEW_MY_COMPANY_INFORMATION}
        createUserAction={userActions.EDIT_MY_COMPANY_INFORMATION}
        detailsSource="myCompany"
      />
      {hasFactoring && <FinancingInfoGroup />}
      {hasFactoring && canEdit && isMerchant && (
        <FieldsGroup
          title={t('Settings')}
          actions={<UpdateCompanySettings />}
        >
          <Box marginBottom="1rem">
            <BooleanField source="entitySettings.alwaysCreateDrafts" label={t('Always create draft')} />
          </Box>
        </FieldsGroup>
      )}
      <EntityInvoiceSettings resource={resources.MY_COMPANY} />
    </FormTab>
  );
};

export default SummaryTab;
