import React, { useCallback, useEffect, useState } from 'react';

import {
  ArrayInput,
  required,
  minValue,
  useRecordContext,
} from 'react-admin';

import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

import { EditModalButton } from '../designSystem/ModalButton';
import BooleanInput from '../designSystem/react-admin/inputs/BooleanInput';
import SelectInput from '../designSystem/react-admin/inputs/SelectInput';
import SelectArrayInput from '../designSystem/react-admin/inputs/SelectArrayInput';
import NumberInput from '../designSystem/react-admin/inputs/NumberInput';
import SimpleFormIterator from '../designSystem/react-admin/SimpleFormIterator';
import PercentageInput from '../designSystem/react-admin/inputs/PercentageInput';
import InputsGroup from '../designSystem/InputsGroup';

import validateFeeField from '../utils/validateFeeField';
import {
  positiveNumberValidation,
} from '../../utils/validationErrors';

import useUserPermissionsAndRoles from '../hooks/useUserPermissionsAndRoles';
import useCustomRpc from '../hooks/useCustomRpc';

import { useConstantContext } from '../ConstantsContext';

import isRecourseProduct from '../../utils/isRecourseProduct';
import useHasUserPermissions from '../hooks/useHasUserPermissions';

const validateRequired = [required()];
// TODO global validation for data consistecy -> sum or reserved <= total limit

const UpdateFinancingSettingsButton = () => {
  const [isRecoursiveProduct, setIsRecoursiveProduct] = useState(false);
  const record = useRecordContext();
  const {
    merchantId,
    financingLimit,
    financingLimitCurrency,
    financingAmountPercentage,
    financialProducts,
    feeSettings,
    feeSettingsV2,
    recourseDays,
    settings,
  } = record;
  const { t } = useTranslation();

  const {
    mutate: setFinancingSettings,
    isLoading,
  } = useCustomRpc({
    path: 'entity-v2/financing-info',
    httpMethod: 'POST',
    shouldRefresh: true,
    errorMessage: t('Could not update the financing settings'),
  });

  const { hasFactoring } = useUserPermissionsAndRoles();
  const { constants, choices } = useConstantContext();

  const {
    currencies,
    rateTypes,
    feeTriggers,
    feeReferenceAmounts,
    dayCountConventions,
    billingMethods,
    financialProducts: financialProductsChoices,
  } = choices;

  const {
    rateTypes: rateTypesConstants,
    feeTriggers: feeTriggersConstants,
    feeReferenceAmounts: feeReferenceAmountsConstants,
    financialProducts: financialProductsConstants,
    userActions,
  } = constants;

  const canEditFinancingInfo = useHasUserPermissions(
    constants,
    userActions.UPDATE_MERCHANT_FINANCIAL_SETTINGS,
  );

  const validate = useCallback(() => validateFeeField({
    rateTypesConstants,
    feeTriggersConstants,
    feeReferenceAmountsConstants,
  }), [
    rateTypesConstants,
    feeTriggersConstants,
    feeReferenceAmountsConstants,
  ]);

  useEffect(() => {
    if (financialProducts && financialProductsConstants) {
      setIsRecoursiveProduct(isRecourseProduct(
        financialProducts,
        financialProductsConstants,
      ));
    }
  }, [setIsRecoursiveProduct, financialProducts, financialProductsConstants]);

  const onSubmit = useCallback((formData) => {
    const isRecourseFinancialProduct = isRecourseProduct(
      formData.financialProducts,
      financialProductsConstants,
    );

    const data = {
      ...formData,
      ...(!isRecourseFinancialProduct ? { recourseDays: undefined } : {}),
      entityCoreId: merchantId,
    };
    setFinancingSettings(data);
    return true;
  }, [
    merchantId,
    setFinancingSettings,
    financialProductsConstants,
  ]);

  const checkIfRecourseProduct = useCallback((e) => {
    const input = e.target.value;
    const isRecourseInput = isRecourseProduct(
      input,
      financialProductsConstants,
    );
    setIsRecoursiveProduct(isRecourseInput);
  }, [setIsRecoursiveProduct, financialProductsConstants]);

  if (!hasFactoring || !canEditFinancingInfo || !merchantId) return null;

  return (
    <EditModalButton
      modalTitle={t('Update financing settings')}
      onClick={onSubmit}
      disabled={isLoading}
      width="47.5rem"
      formDefaultValue={{
        merchantId,
        financingLimit,
        financingLimitCurrency,
        financingAmountPercentage,
        financialProducts,
        feeSettings,
        feeSettingsV2,
        recourseDays,
        settings,
      }}
    >
      <InputsGroup layout="column">
        <Box display="flex" gap="1.5rem">
          <NumberInput
            label={t('Financing limit')}
            source="financingLimit"
            validate={[positiveNumberValidation, ...validateRequired]}
          />
          <SelectInput
            label={t('Currency')}
            source="financingLimitCurrency"
            choices={currencies}
            validate={validateRequired}
          />
        </Box>
        <PercentageInput
          label={t('Financing percentage')}
          source="financingAmountPercentage"
          validate={validateRequired}
        />
        <SelectArrayInput
          variant="filled"
          label={t('Financial products')}
          source="financialProducts"
          choices={financialProductsChoices}
          onChange={checkIfRecourseProduct}
        />
        {isRecoursiveProduct && (
          <NumberInput
            label={t('Recourse Days')}
            source="recourseDays"
            validate={[positiveNumberValidation]}
          />
        )}
      </InputsGroup>
      <InputsGroup title={t('Fee settings')}>
        <ArrayInput
          source="feeSettingsV2"
          label={false}
        >
          <SimpleFormIterator addButtonLabel={t('Add new fee setting')} itemLabel={t('Fee setting')}>
            <InputsGroup layout="column">
              <SelectInput
                label={t('Fee Currency')}
                source="currency"
                choices={currencies}
                validate={validateRequired}
              />
              <SelectInput
                label={t('Rate type')}
                source="rateType"
                choices={rateTypes}
                validate={validateRequired}
              />
              <SelectInput
                label={t('Trigger')}
                source="feeTrigger"
                choices={feeTriggers}
                validate={[validate(), ...validateRequired]}
              />
              <SelectInput
                label={t('Billing method')}
                source="billingMethod"
                choices={billingMethods}
                validate={validateRequired}
              />
              <SelectInput
                label={t('Reference amount')}
                source="feeReferenceAmount"
                choices={feeReferenceAmounts}
                validate={[validate(), ...validateRequired]}
              />
              <SelectInput
                label={t('Day count convention')}
                source="dayCountConvention"
                choices={dayCountConventions}
                validate={validate()}
              />
              <PercentageInput
                label={t('Fee percentage')}
                source="percentageValue"
                validate={validateRequired}
              />
              <NumberInput
                label={t('Fee cap')}
                source="cap.value"
                min={0}
                validate={[minValue(0)]}
              />
              <PercentageInput
                label={t('VAT percentage')}
                source="vatPercentage"
              />
            </InputsGroup>
          </SimpleFormIterator>
        </ArrayInput>
      </InputsGroup>
      <InputsGroup title={t('Permission settings')}>
        <BooleanInput
          source="settings.allowMerchantPayment"
          label={t('Allow merchant payment')}
          helperText={t('Allow this merchant to upload payment files for their invoices. If deactivated, the financer will be responsible to upload the merchants payments which will also be mirrored in the settlement account')}
        />
        <BooleanInput
          source="settings.enableCreditNotes"
          label={t('Credit notes')}
          helperText="Allow merchant to credit note their invoices"
        />
        <BooleanInput
          source="settings.allowSettlementOverdraft"
          label={t('Allow settlement account overdraft')}
          helperText={t('If activated, the system will withdraw money from the merchant settlement account to balance the factoring accounts and close the financement, even when the settlement account has insufficient funds to cover it')}
        />
        <BooleanInput
          source="settings.parkBillingAccountMonthly"
          label={t('Automatically prepare billing account monthly')}
          helperText={t('If activated, the system will move the open billing accounts to Ready to charge on the first of each month after the financing monthly fees are charged')}
        />
        <BooleanInput
          source="settings.canRequestFinancingAmount"
          label={t('Allow requesting financing amount')}
          helperText={t('If activated, the merchant is allowed to request the financing amount when creating orders')}
        />
      </InputsGroup>
    </EditModalButton>
  );
};

export default UpdateFinancingSettingsButton;
