import React from 'react';
import {
  ArrayField,
  FunctionField,
  useRecordContext,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import CurrencyField from '../utils/CurrencyField';
import SimpleShowLabeled from '../utils/SimpleShowLabeled';

import Datagrid from '../designSystem/react-admin/Datagrid';
import TitleH4 from '../designSystem/TitleH4';

import TransactionGrid from './TransactionGrid';

const SettlementAccountBreakdown = () => {
  const {
    settlementsInOrderCurrency,
    currency,
  } = useRecordContext();
  const { t } = useTranslation();

  const shouldDisplayBreakDown = settlementsInOrderCurrency
    && settlementsInOrderCurrency.length > 0
    && (settlementsInOrderCurrency.length > 1
    || settlementsInOrderCurrency[0].orderCurrency !== currency);

  return (
    <>
      {shouldDisplayBreakDown
        && (
          <>
            <TitleH4 title={t('Currency breakdown')} margin="1.2rem 0 -0.3rem" />
            <SimpleShowLabeled>
              <ArrayField source="settlementsInOrderCurrency" label="">
                <Datagrid
                  variant="greyBackground"
                  bulkActionButtons={false}
                  sx={{ marginBottom: '0.5rem' }}
                  rightAlignedColumns={['convertedSum', 'amountInFinancingCurrency']}
                >
                  <CurrencyField source="convertedSum" currencySource="orderCurrency" label={t('Order currency')} />
                  <FunctionField
                    label={t('Financing currency')}
                    render={(record) => <CurrencyField customRecord={{ ...record, currency }} source="originalSum" currencySource="currency" label={t('Financing currency')} />}
                  />
                </Datagrid>
              </ArrayField>
            </SimpleShowLabeled>
          </>
        )}
      <TitleH4 title={t('Transactions')} margin="1.2rem 0 -0.3rem" />
      <SimpleShowLabeled fullWidth>
        <ArrayField source="transactions" label="">
          <TransactionGrid />
        </ArrayField>
      </SimpleShowLabeled>
    </>
  );
};

export default SettlementAccountBreakdown;
