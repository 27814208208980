import React from 'react';

import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import { ChildrenProp } from '../../utils/helpers/propTypes';

const ChartContainer = ({ children }) => (
  <Card sx={{ height: '100%' }}>
    <CardContent>
      {children}
    </CardContent>
  </Card>
);

ChartContainer.propTypes = {
  children: ChildrenProp.isRequired,
};

export default ChartContainer;
