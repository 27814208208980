import React from 'react';
import PropTypes from 'prop-types';

import {
  TextField,
  ReferenceField,
  FunctionField,
  useRecordContext,
} from 'react-admin';

import { useLocation } from 'react-router-dom';

import { Box, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/InfoOutlined';

import { useTranslation } from 'react-i18next';

import { useConstantContext } from '../ConstantsContext';

const StyledBox = ({ children }) => (
  <Box
    display="flex"
    flexDirection="row"
    justifyContent="flex-start"
    alignContent="center"
    columnGap="0.31rem"
  >
    {children}
    <PartnerTooltip />
  </Box>
);

StyledBox.propTypes = {
  children: PropTypes.node.isRequired,
};

const PartnerTooltip = () => {
  const { t } = useTranslation();

  return (
    <Tooltip title={t('This billing is mirrored from the partner, only the partner can charge billings in settlements and move billings to invoiced.')}>
      <InfoIcon color="secondary" fontSize="small" />
    </Tooltip>
  );
};

const FinancerName = () => {
  const { partnerId, partnerBillingId } = useRecordContext();
  const { clientInfo } = useConstantContext();
  const { pathname } = useLocation();
  const isBilling = pathname.includes('billing');
  const checkedId = isBilling ? partnerBillingId : partnerId;

  const displayPartnerId = checkedId
    && clientInfo.defaultPartnerFinancer;

  return (
    displayPartnerId ? (
      <ReferenceField
        source="partnerId"
        reference="entity-v2/core"
        emptyText="-"
        link={false}
      >
        {isBilling ? (
          <StyledBox>
            <FunctionField render={({ name }) => `${name}`} />
          </StyledBox>
        ) : (
          <FunctionField render={({ name }) => `${name}`} />
        )}
      </ReferenceField>
    ) : (
      <TextField source="creditorName" />
    )
  );
};

export default FinancerName;
