import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';

export const colors = {
  green: {
    text: '#093E00',
    background: '#CCECC7',
  },
  orange: {
    text: '#4B3F00',
    background: '#FFF0BA',
  },
  red: {
    text: '#F95738',
    background: '#FFE3DE',
  },
  gray: {
    text: 'white',
    background: 'grey',
  },
};

const StyledChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'chipColor',
})(({ chipColor, fontSize }) => ({
  backgroundColor: chipColor.background,
  color: chipColor.text,
  fontSize,
  fontWeight: 'bold',
  width: '9.5rem',
  padding: '0.3rem 0',
}));

const StatusChip = StyledChip;

export default StatusChip;
