import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';

import EmptyData from '../EmptyData';
import { isArray } from '../../utils/validators';
import { ChildrenProp } from '../../utils/helpers/propTypes';

const FieldsGroup = ({
  title,
  children,
  actions,
}) => (
  <Box
    display="flex"
    flexDirection="column"
    alignSelf="stretch"
    marginBottom="2rem"
  >
    <Card sx={{ padding: '1rem 0.5rem 1rem 0.5rem' }}>
      <CardHeader
        action={actions}
        title={title}
        titleTypographyProps={{ variant: 'h6', fontWeight: '600' }}
      />
      <CardContent sx={{ paddingTop: '0.5rem' }}>
        {!children || (isArray(children) && children.length === 0)
          ? <EmptyData />
          : children}
      </CardContent>
    </Card>
  </Box>
);

FieldsGroup.propTypes = {
  title: PropTypes.node,
  actions: PropTypes.node,
  children: ChildrenProp.isRequired,
};

FieldsGroup.defaultProps = {
  actions: null,
  title: undefined,
};

export default FieldsGroup;
