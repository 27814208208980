import React, { useState, useCallback } from 'react';
import { Form, required } from 'react-admin';
import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { styled, useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import CircularProgress from '@mui/material/CircularProgress';

import TitleH2 from './TitleH2';
import TitleH5 from './TitleH5';

import SelectInput from './react-admin/inputs/SelectInput';
import FileInput from './react-admin/inputs/FileInput';
import Button from './Button';

import EnhancedFileField from '../utils/EnhancedFileFields';
import UploadedFileSummary from '../utils/UploadedFileSummary';

import { ReactComponent as Download } from '../../../assets/download.svg';

import useUploadFileCustomRpc from '../hooks/useUploadFileCustomRpc';

import { isDefined } from '../../utils/validators';
import { ChildrenProp } from '../../utils/helpers/propTypes';

const MODAL_PADDING = '2.5rem';

const StyledSection = styled('section')(({ withForm }) => ({
  height: 'auto',
  borderRadius: '0.8rem',
  minWidth: 'min(15rem, 90%)',
  minHeight: 'min(12rem, 90%)',
  maxWidth: 'min(50rem, 90%)',
  maxHeight: '80%',
  padding: MODAL_PADDING,
  boxShadow: '0px 5px 8px 0px rgba(0, 0, 0, 0.2)',
  position: 'absolute',
  backgroundColor: 'white',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  ...(withForm ? {
    '& form': {
      display: 'flex',
      flexDirection: 'column',
      gap: '1.25rem',
    },
  } : {}),
}));

const ModalActionBar = ({
  hideCloseButton,
  handleCloseModal,
  hideSubmitButton,
  handleClick,
  disabled,
  buttonLabel,
}) => {
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      marginTop="1.25rem"
      gap="1.5rem"
      justifyContent="flex-end"
    >
      {hideCloseButton ? null : (
        <Button
          variant="contained"
          onClick={handleCloseModal}
          color="grey"
          sx={{ padding: '0.5rem 2rem' }}
        >
          {t('Cancel')}
        </Button>
      )}
      {hideSubmitButton ? null : (
        <Button
          type="submit"
          variant="contained"
          onClick={handleClick}
          disabled={disabled}
          color="secondary"
          sx={{ padding: '0.5rem 2rem' }}
        >
          {buttonLabel || t('Save')}
        </Button>
      )}
    </Box>
  );
};

ModalActionBar.propTypes = {
  handleClick: PropTypes.func,
  disabled: PropTypes.bool.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  hideSubmitButton: PropTypes.bool.isRequired,
  hideCloseButton: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
};

ModalActionBar.defaultProps = {
  handleClick: undefined,
};

const ModalButton = (props) => {
  const {
    disabled,
    modalTitle,
    actionLabel,
    openButtonLabel,
    children,
    onClick,
    onOpen,
    onClose,
    hideSubmitButton,
    hideCloseButton,
    sx,
    formDefaultValue,
    variant,
    startIcon,
    width,
    disabledTooltip,
  } = props;
  const [open, setOpen] = useState(false);

  const handleOpenModal = useCallback((e) => {
    e.preventDefault();
    onOpen();
    setOpen(true);
  }, [onOpen]);

  const handleCloseModal = useCallback((e) => {
    e.preventDefault();
    onClose();
    setOpen(false);
  }, [onClose]);

  const onSubmitForm = useCallback((data, e) => {
    const submitResult = onClick(data);
    if (submitResult) {
      handleCloseModal(e);
    }
  }, [onClick, handleCloseModal]);

  const onSubmitNoForm = useCallback((e) => {
    e.preventDefault();
    const submitResult = onClick();
    if (submitResult) {
      handleCloseModal(e);
    }
  }, [onClick, handleCloseModal]);

  const withForm = isDefined(formDefaultValue);
  return (
    <>
      <Button
        disabled={disabled}
        onClick={handleOpenModal}
        variant={variant}
        color="primary"
        size="small"
        sx={sx}
        startIcon={startIcon}
        disabledTooltip={disabledTooltip}
      >
        {openButtonLabel}
      </Button>
      <Modal
        open={open}
        onClose={handleCloseModal}
      >
        <StyledSection withForm={withForm}>
          <Box display="flex" gap="1.25rem" flexDirection="column" width={`calc(${width} - 2 * ${MODAL_PADDING})`}>
            <TitleH2 title={modalTitle} />
            {withForm ? (
              <Form
                record={formDefaultValue}
                onSubmit={onSubmitForm}
                sanitizeEmptyValues
              >
                {children}
                <ModalActionBar
                  hideSubmitButton={hideSubmitButton}
                  hideCloseButton={hideCloseButton}
                  disabled={disabled}
                  buttonLabel={actionLabel}
                  handleCloseModal={handleCloseModal}
                />
              </Form>
            ) : (
              <>
                {children}
                <ModalActionBar
                  handleClick={onSubmitNoForm}
                  hideSubmitButton={hideSubmitButton}
                  hideCloseButton={hideCloseButton}
                  disabled={disabled}
                  buttonLabel={actionLabel}
                  handleCloseModal={handleCloseModal}
                />
              </>
            )}
          </Box>
        </StyledSection>
      </Modal>
    </>
  );
};

const ModalButtonPropTypes = {
  openButtonLabel: PropTypes.string.isRequired,
  modalTitle: PropTypes.string.isRequired,
  actionLabel: PropTypes.string.isRequired,
  variant: PropTypes.string,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  hideSubmitButton: PropTypes.bool,
  hideCloseButton: PropTypes.bool,
  sx: PropTypes.shape({}),
  formDefaultValue: PropTypes.shape({}),
  startIcon: PropTypes.node,
  width: PropTypes.string,
  ...Button.propTypes,
};
ModalButton.propTypes = {
  ...ModalButtonPropTypes,
  children: ChildrenProp.isRequired,
};

ModalButton.defaultProps = {
  onClose: () => null,
  onOpen: () => null,
  onClick: () => null,
  hideSubmitButton: false,
  hideCloseButton: false,
  sx: {},
  formDefaultValue: undefined,
  variant: 'contained',
  startIcon: undefined,
  width: undefined,
  ...Button.defaultProps,
};

export const EditModalButton = (props) => {
  const { t } = useTranslation();
  return (
    <ModalButton
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      startIcon={<BorderColorOutlinedIcon />}
      openButtonLabel={t('Edit')}
      actionLabel={t('Save')}
      sx={{ margin: '0.5rem' }}
    />
  );
};

const DownloadTemplate = ({ downloadableTemplateTypes, templateDownloadButton }) => {
  const { t } = useTranslation();
  const templateType = useWatch({ name: 'templateType' });

  // Proper way would be to always pass a valid array there
  // but we don't have the merchant templates in the choices at the moment
  const allowDownload = !downloadableTemplateTypes
    || downloadableTemplateTypes.find((type) => type.id === templateType);

  if (!templateDownloadButton || !allowDownload) return null;

  return (
    <Box display="flex" gap="1.25rem" flexDirection="column">
      <TitleH5 title={t('Information')} marginBottom="0" />
      <Typography fontSize="15px">{t('To make sure the data is converted correctly you can use the template below')}</Typography>
      <Box>
        {React.cloneElement(templateDownloadButton, {
          sx: { marginLeft: '-1rem' },
          variant: 'blueBackgroundOnHover',
          startIcon: <Download />,
        })}
      </Box>
    </Box>
  );
};

DownloadTemplate.propTypes = {
  templateDownloadButton: PropTypes.node,
  downloadableTemplateTypes: PropTypes.arrayOf(PropTypes.shape()),
};

DownloadTemplate.defaultProps = {
  templateDownloadButton: undefined,
  downloadableTemplateTypes: undefined,
};

export const ProcessableFileUploadModalButton = ({
  accept,
  choices,
  selectTemplateInput,
  ModalButtonProps,
  templateDownloadButton,
  uploadPath,
  downloadableTemplateTypes,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const {
    mutate: uploadProcessableFile,
    isLoading,
    reset: resetProcessableFile,
    data: summary,
  } = useUploadFileCustomRpc({
    httpMethod: 'POST',
    path: uploadPath,
    errorMessage: t('Failed to upload file'),
    withPolling: false,
  });

  const onSubmitUpload = useCallback(({ file, templateType, fileType }) => {
    uploadProcessableFile(file.rawFile, { templateType, fileType });
  }, [uploadProcessableFile]);

  const onClose = useCallback(resetProcessableFile, [resetProcessableFile]);

  const ProcessableFileUploadModalButtonContent = (
    summary ? (
      <UploadedFileSummary
        summary={summary}
        successLabel={t('Successful operations')}
        infoLabel={t('Information')}
        warningLabel={t('Warnings')}
        errorLabel={t('Errors processing file')}
      />
    ) : (
      <>
        {selectTemplateInput || (choices.length > 1 && (
          <SelectInput
            label={t('Select one option')}
            source="templateType"
            validate={[required(t('A template type is required'))]}
            choices={choices}
          />
        ))}
        <FileInput
          label={false}
          source="file"
          accept={accept}
          validate={[required(t('Please upload an order file'))]}
          placeHolder={<Typography fontSize="12px">{t('Drag and drop one file here or you can')} <br /><span style={{ fontWeight: 'bold', color: theme.palette.secondary.main }}>{t('click to select a file')}</span></Typography>}
        >
          <EnhancedFileField source="src" title={t('title')} />
        </FileInput>
        <DownloadTemplate templateDownloadButton={templateDownloadButton} downloadableTemplateTypes={downloadableTemplateTypes} />
      </>
    )
  );
  return (
    <ModalButton
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...ModalButtonProps}
      hideSubmitButton={!!summary || isLoading}
      onClick={onSubmitUpload}
      onClose={onClose}
      width="30rem"
    >
      {isLoading
        ? <CircularProgress color="secondary" />
        : ProcessableFileUploadModalButtonContent}
    </ModalButton>
  );
};

ProcessableFileUploadModalButton.propTypes = {
  accept: PropTypes.string.isRequired,
  uploadPath: PropTypes.string.isRequired,
  choices: PropTypes.arrayOf(PropTypes.shape()),
  ModalButtonProps: PropTypes.shape(ModalButtonPropTypes).isRequired,
  templateDownloadButton: PropTypes.node,
  selectTemplateInput: PropTypes.node,
  downloadableTemplateTypes: PropTypes.arrayOf(PropTypes.shape()),
};

ProcessableFileUploadModalButton.defaultProps = {
  templateDownloadButton: undefined,
  selectTemplateInput: undefined,
  downloadableTemplateTypes: undefined,
  choices: [],
};

export default ModalButton;
