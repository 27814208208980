import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useConstantContext } from '../../ConstantsContext';

import renderReference from '../../../utils/renderReference';
import useUserPermissionsAndRoles from '../../hooks/useUserPermissionsAndRoles';

import ReferenceArrayInput from '../../designSystem/react-admin/inputs/ReferenceArrayInput';
import AutocompleteArrayInput from '../../designSystem/react-admin/inputs/AutocompleteArrayInput';
import DateInput from '../../designSystem/react-admin/inputs/DateInput';

const useCustomerFilters = () => {
  const { constants } = useConstantContext();
  const { t } = useTranslation();
  const { entityRoles } = constants;
  const { isFinancer } = useUserPermissionsAndRoles();

  return useState([
    <DateInput
      source="filters.createdDateTo"
      label={t('Customers created until')}
    />,
    <ReferenceArrayInput
      label={t('Customer')}
      source="filters.debtor"
      reference="entity-v2/reference"
      resource="/entity-v2/reference"
      filter={{ entityRoles: entityRoles.CUSTOMER }}
    >
      <AutocompleteArrayInput
        label={t('Customer')}
        optionText={renderReference}
        suggestionLimit={20}
        options={{
          suggestionsContainerProps: {
            style: { zIndex: 1500 },
          },
        }}
      />
    </ReferenceArrayInput>,
    isFinancer && (
      <ReferenceArrayInput
        label={t('Merchant')}
        source="filters.merchant"
        reference="entity-v2/reference"
        resource="/entity-v2/reference"
        filter={{ entityRoles: entityRoles.MERCHANT }}
      >
        <AutocompleteArrayInput
          label={t('Merchant')}
          optionText={renderReference}
          suggestionLimit={20}
          options={{
            suggestionsContainerProps: {
              style: { zIndex: 1500 },
            },
          }}
        />
      </ReferenceArrayInput>
    ),
  ].filter((input) => !!input));
};

export default useCustomerFilters;
