import { t } from 'i18next';

const mapErrorCodeToDescription = (errorCode) => {
  let error;

  switch (errorCode) {
    case '15':
      error = t('No match record');
      break;
    case 'S200':
      error = t('Inactive');
      break;
    case 'S231':
      error = t('Liquidation concluded');
      break;
    case 'S241':
      error = t('Merger concluded');
      break;
    case 'S291':
      error = t('Bankruptcy concluded');
      break;
    case 'S292':
      error = t('Bankruptcy concluded');
      break;
    case 'S300':
      error = t('Delisted');
      break;
    case 'S336':
      error = t('Company stricken off according to the Companies Act 13:18');
      break;
    case 'S337':
      error = t('Company stricken off');
      break;
    case 'S350':
      error = t('Stricken off according to the Partnership and Non-registered Partnership Act 17:2');
      break;
    case 'S351':
      error = t('Stricken off according to the Economic Association (cooperative) Act 11:18');
      break;
    case 'S352':
      error = t('Delisted');
      break;
    case 'S353':
      error = t('Delisted due to new owner');
      break;
    case 'S354':
      error = t('Delisted due to fusion with foreign company');
      break;
    case 'S360':
      error = t('Delisted due to liquidation/bankruptcy of foreign company');
      break;
    case 'S361':
      error = t('Delisted due to discontinuation of business operation');
      break;
    case 'S362':
      error = t('Delisted, the branch office has no managing director');
      break;
    case 'S363':
      error = t('Delisted, according to court order');
      break;
    case 'S364':
      error = t('Delisted, annual report lacking');
      break;
    case 'S370':
      error = t('Delisted at own request');
      break;
    case 'S371':
      error = t('Delisted by request from Bolagsverket (Companies House)');
      break;
    case 'S373':
      error = t('Stricken off');
      break;
    case 'S374':
      error = t('Stricken off, re-registered as a joint-stock bank');
      break;
    case 'S377':
      error = t('Delisted due to conversion');
      break;
    default:
      throw new Error('Could not match error code to description');
  }
  return error;
};

export default mapErrorCodeToDescription;
