import React, { useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';

import {
  useNotify,
  useRecordContext,
  // required,
  // FormDataConsumer,
} from 'react-admin';

// import { useFormContext } from 'react-hook-form';

import useCustomRpc from '../hooks/useCustomRpc';

import ModalButton from '../designSystem/ModalButton';
// import BooleanInput from '../designSystem/react-admin/inputs/BooleanInput';
// import NumberInput from '../designSystem/react-admin/inputs/NumberInput';

// import { positiveNumberValidation } from '../../utils/validationErrors';

// const ShouldSendInvoicesToggle = () => {
//   const [featureFlags] = useFeatureFlags();

//   const { setValue } = useFormContext();

//   const onChange = useCallback(() => {
//     setValue('termsOfPayment', null);
//   }, [setValue]);

//   return (
//     <BooleanInput
//       label="Send invoice to merchant via email"
//       source="shouldSendInvoices"
//       onChange={onChange}
//     />
//   );
// };

const MarkAsInvoicedButton = () => {
  const { id: accountId, invoicingSettings } = useRecordContext();
  const { t } = useTranslation();
  const record = useRecordContext();
  // const validateRequired = [required()];
  const notify = useNotify();

  const path = `account/${accountId}/mark-as-invoiced-v2`;

  const showNotification = useCallback(() => {
    notify(
      t('The billing was successfully moved to the invoiced list and marked as invoiced externally.'),
      {
        multiLine: true,
        autoHideDuration: 5000,
      },
    );
  }, [notify, t]);

  const {
    mutate: markAsInvoiced,
    isLoading,
  } = useCustomRpc({
    path,
    httpMethod: 'PUT',
    shouldRefresh: true,
    onSuccess: showNotification,
    errorMessage: t('Could not mark as invoiced'),
  });

  const handleClick = useCallback(({ shouldSendInvoices, termsOfPayment }) => {
    markAsInvoiced({
      invoicingSettings: {
        shouldSendInvoices,
        termsOfPayment,
      },
    });
    return true;
  }, [markAsInvoiced]);

  if (record.partnerBillingId) return null;

  return (
    <ModalButton
      modalTitle={t('Mark as externally invoiced')}
      openButtonLabel={t('Mark as externally invoiced')}
      onClick={handleClick}
      disabled={isLoading}
      actionLabel={t('Charge')}
      variant="subAction"
      width="30rem"
      formDefaultValue={{
        shouldSendInvoices: !!(invoicingSettings && invoicingSettings.shouldSendInvoices),
      }}
    >
      <Typography paragraph>{t('The billing account will be marked as invoiced')}</Typography>
      {/**
       * --- Hide invoicing module for billing account until the feature is fully ready ---
       *
       * <Typography paragraph>The billing account will be marked as invoiced. If you want to send an invoice to the merchant, use the toggle below.</Typography>
       * <ShouldSendInvoicesToggle />
       * <FormDataConsumer>
       *   {({ formData }) => formData.shouldSendInvoices && (
       *     <NumberInput
       *       label="Terms of payment"
       *       source="termsOfPayment"
       *       validate={[positiveNumberValidation, ...validateRequired]}
       *     />
       *   )}
       * </FormDataConsumer>
       */}
      <Typography>{t('This action cannot be reverted.')}</Typography>
    </ModalButton>
  );
};

export default MarkAsInvoicedButton;
