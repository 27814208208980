import React from 'react';
import { FormTab } from 'react-admin';
import { useTranslation } from 'react-i18next';

import CreditInfoGroup from '../../CreditInfoGroup';
import AssignedCreditInfoGroup from '../../AssignedCreditInfoGroup'; // What is this?
import KycGroup from '../../KycGroup';
import PendingApplicationsInfoGroup from '../../PendingApplicationsInfoGroup';

import useFeatureFlags from '../../../hooks/useFeatureFlags';

const FinancingKycTab = (props) => {
  const { t } = useTranslation();
  const [featureFlags] = useFeatureFlags();

  if (!featureFlags.enableAnalyticsAndRedesign) return null;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormTab label={t('Financing & KYC')} {...props}>
      <CreditInfoGroup />
      <AssignedCreditInfoGroup />
      <KycGroup />
      <PendingApplicationsInfoGroup />
    </FormTab>
  );
};

export default FinancingKycTab;
