import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useListContext,
  useRecordContext,
} from 'react-admin';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';

import useDownloadDocument from '../hooks/useDownloadDocument';

import { useConstantContext } from '../ConstantsContext';
import useHasUserPermissions from '../hooks/useHasUserPermissions';

import ModalButton from '../designSystem/ModalButton';

const DownloadOutboundPaymentButton = (props) => {
  const {
    color,
    size,
    variant,
    sx,
    disabled,
    disabledTooltip,
  } = props;

  const { selectedIds } = useListContext();
  const { pathname } = useLocation();
  const record = useRecordContext();
  const { t } = useTranslation();
  const { id: accountId } = record || {};

  const { constants } = useConstantContext();
  const { userActions } = constants;

  const {
    mutate: downloadFinancerOutboundPaymentReport,
    isLoading,
  } = useDownloadDocument({
    path: 'account/outbound-payment',
    errorMessage: t('Could not retrieve outbound payment report'),
    shouldRefresh: true,
    shouldUnselectAll: 'settlement-account',
  });

  const canEditSettlement = useHasUserPermissions(
    constants,
    userActions.MANAGE_SETTLEMENT_ACCOUNTS,
  );

  const handleClick = useCallback(() => {
    downloadFinancerOutboundPaymentReport({
      paramId: accountId || selectedIds,
      payload: pathname,
    });
    return true;
  }, [accountId, pathname, downloadFinancerOutboundPaymentReport, selectedIds]);

  if (!canEditSettlement) return null;
  if (record && record.closedAt) return null;

  return (
    <ModalButton
      modalTitle={t('Make payout')}
      openButtonLabel={t('Make payout')}
      actionLabel={t('Make payout')}
      disabled={disabled || isLoading}
      onClick={handleClick}
      variant={variant}
      color={color}
      size={size}
      sx={{ ...sx }}
      width="30rem"
      disabledTooltip={disabledTooltip}
    >
      <Typography paragraph>
        {selectedIds && selectedIds.length > 1 ? (
          <>{t('A list of transactions will be generated to clear the negative balances where you owe your client.')}</>
        ) : <>{t('A transaction will be generated to clear the negative balance you owe your client.')}</>}
      </Typography>
      <Typography>{t('This action cannot be reverted.')}</Typography>
    </ModalButton>
  );
};

DownloadOutboundPaymentButton.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
  sx: PropTypes.shape(),
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  disabledTooltip: PropTypes.string,
};

DownloadOutboundPaymentButton.defaultProps = {
  color: undefined,
  size: undefined,
  sx: undefined,
  variant: 'contained',
  disabled: false,
  disabledTooltip: undefined,
};

export default DownloadOutboundPaymentButton;
