import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  ArrayInput,
  required,
  maxValue,
  minValue,
  FormDataConsumer,
  useRecordContext,
  useGetMany,
  FunctionField,
} from 'react-admin';
import { useWatch, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';

import { feeLengthValidation, strictlyPositiveNumberValidation } from '../../utils/validationErrors';
import roundNumber from '../../utils/roundNumber';

import validateFeeField from '../utils/validateFeeField';

import { useConstantContext } from '../ConstantsContext';

import SelectInput from '../designSystem/react-admin/inputs/SelectInput';
import NumberInput from '../designSystem/react-admin/inputs/NumberInput';
import PercentageInput from '../designSystem/react-admin/inputs/PercentageInput';
import SimpleFormIterator from '../designSystem/react-admin/SimpleFormIterator';
import InputsGroup from '../designSystem/InputsGroup';

import SimpleShowLabeled from '../utils/SimpleShowLabeled';

const validateRequired = [required()];
const validateFeeLength = [feeLengthValidation(), ...validateRequired];

const FinancingApplicationTermsForm = ({
  merchantId,
  orderAmount,
  orderCurrency,
  termsFinancingCurrency,
  termsFeeSettings,
}) => {
  const { setValue, getValues } = useFormContext();
  const { t } = useTranslation();

  const { data: financingInfo, isLoading, error } = useGetMany(
    'entity-v2/financing-info',
    { ids: [merchantId] },
  );

  const record = useRecordContext();
  const percentageAmount = (record.financeAmount / (orderAmount * (record.fxRate || 1))) * 100;

  const financingCurrency = useWatch({ name: 'financingCurrency' });
  const fxRate = useWatch({ name: 'fxRate' }) || 1;
  const feeSettings = useWatch({ name: 'feeSettings' });
  const convertedOrderAmount = useMemo(() => orderAmount * (fxRate > 0 ? fxRate : 1), [
    orderAmount,
    fxRate,
  ]);
  const currentPercentage = getValues('percentageAmount') || percentageAmount;

  const onChangeFinanceAmount = useCallback((e) => {
    setValue('percentageAmount', (e.target.value / convertedOrderAmount) * 100);
  }, [convertedOrderAmount, setValue]);

  const onChangePercentageAmount = useCallback((e) => {
    setValue('financeAmount', (e.target.value / 100) * convertedOrderAmount);
  }, [convertedOrderAmount, setValue]);

  const onChangeFinancingCurrency = useCallback((e) => {
    const inputFinancingCurrency = e.target.value;
    setValue('fxRate', 1);
    setValue('financeAmount', orderAmount * (currentPercentage / 100));
    let feeSettingsCurrency;
    if (termsFinancingCurrency === inputFinancingCurrency) {
      feeSettingsCurrency = [...termsFeeSettings];
    } else {
      feeSettingsCurrency = financingInfo && financingInfo[0].feeSettingsV2
        ? financingInfo[0].feeSettingsV2.filter(
          (fee) => fee.currency === inputFinancingCurrency,
        ) : [];
    }
    setValue('feeSettings', feeSettingsCurrency);
  }, [
    currentPercentage,
    orderAmount,
    setValue,
    financingInfo,
    termsFeeSettings,
    termsFinancingCurrency,
  ]);

  const onChangeFxRate = useCallback((e) => {
    const parsedFxRate = parseFloat(e.target.value) || 1;
    const newOrderAmount = orderAmount * parsedFxRate;
    setValue('financeAmount', newOrderAmount * (currentPercentage / 100));
  }, [orderAmount, currentPercentage, setValue]);

  const { constants, choices } = useConstantContext();
  const {
    rateTypes,
    feeTriggers,
    feeReferenceAmounts,
    dayCountConventions,
    billingMethods,
    currencies,
  } = choices;
  const {
    rateTypes: rateTypesConstants,
    feeTriggers: feeTriggersConstants,
    feeReferenceAmounts: feeReferenceAmountsConstants,
  } = constants;

  const validate = validateFeeField({
    rateTypesConstants,
    feeTriggersConstants,
    feeReferenceAmountsConstants,
  });

  if (isLoading || error) return null;

  return (
    <>
      {financingCurrency !== orderCurrency && (
        <FormDataConsumer>
          {({ formData }) => !!formData.financeAmount && !!formData.fxRate && (
            <SimpleShowLabeled>
              <FunctionField
                label={t('Finance amount in order currency')}
                render={() => `${roundNumber(formData.financeAmount / formData.fxRate, 4)} ${orderCurrency}`}
              />
            </SimpleShowLabeled>
          )}
        </FormDataConsumer>
      )}
      <InputsGroup layout="column">
        <Box display="flex" gap="1.5rem">
          <NumberInput
            label={t('Financing amount')}
            source="financeAmount"
            min={0}
            max={convertedOrderAmount}
            validate={[minValue(0), maxValue(convertedOrderAmount), ...validateRequired]}
            onChange={onChangeFinanceAmount}
          />
          <SelectInput
            label={t('Currency')}
            source="financingCurrency"
            choices={currencies}
            validate={validateRequired}
            onChange={onChangeFinancingCurrency}
          />
        </Box>
        <PercentageInput
          label={`${t('Financing amount')} (%)`}
          source="percentageAmount"
          defaultValue={percentageAmount}
          onChange={onChangePercentageAmount}
        />
        {financingCurrency !== orderCurrency && (
          <NumberInput
            label={t('Financing FX rate')}
            source="fxRate"
            InputProps={{ endAdornment: <InputAdornment position="end">{orderCurrency} {t('to')} {financingCurrency}</InputAdornment> }}
            validate={strictlyPositiveNumberValidation}
            onChange={onChangeFxRate}
          />
        )}
      </InputsGroup>
      {feeSettings.length === 0 && (
        <Typography color={(theme) => theme.palette.warning.main} marginBottom="1rem">{t('No fee setting found for this currency, please add this below or in the merchant details')}</Typography>
      )}
      <InputsGroup title={t('Fees')}>
        <ArrayInput source="feeSettings" label={false} validate={validateRequired}>
          <SimpleFormIterator validate={validateFeeLength} addButtonLabel={t('Add new fee setting')} itemLabel={t('Fee setting')}>
            <InputsGroup layout="column">
              <SelectInput
                label={t('Rate type')}
                source="rateType"
                choices={rateTypes}
                validate={validateRequired}
              />
              <SelectInput
                label={t('Trigger')}
                source="feeTrigger"
                choices={feeTriggers}
                validate={[validate, ...validateRequired]}
              />
              <SelectInput
                label={t('Billing method')}
                source="billingMethod"
                choices={billingMethods}
                validate={validateRequired}
              />
              <SelectInput
                label={t('Reference amount')}
                source="feeReferenceAmount"
                choices={feeReferenceAmounts}
                validate={[validate, ...validateRequired]}
              />
              <SelectInput
                label={t('Day count convention')}
                source="dayCountConvention"
                choices={dayCountConventions}
                validate={validate}
              />
              <PercentageInput
                label={t('Fee percentage')}
                source="percentageValue"
                validate={validateRequired}
              />
              <NumberInput
                label={t('Fee cap')}
                source="cap.value"
                min={0}
                validate={[minValue(0)]}
              />
              <PercentageInput
                label={t('VAT percentage')}
                source="vatPercentage"
              />
            </InputsGroup>
          </SimpleFormIterator>
        </ArrayInput>
      </InputsGroup>
    </>
  );
};

FinancingApplicationTermsForm.propTypes = {
  orderAmount: PropTypes.number.isRequired,
  orderCurrency: PropTypes.string.isRequired,
  merchantId: PropTypes.string.isRequired,
  termsFinancingCurrency: PropTypes.string.isRequired,
  termsFeeSettings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default FinancingApplicationTermsForm;
