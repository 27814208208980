import React from 'react';
import {
  DateField,
  TextField,
  useRecordContext,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid';

import FieldsGroup from '../../utils/FieldsGroup';
import SimpleShowLabeled from '../../utils/SimpleShowLabeled';

import ConfirmKycButton from './ConfirmKycButton';

import useUserPermissionsAndRoles from '../../hooks/useUserPermissionsAndRoles';

const KycInformation = () => {
  const { isFinancer } = useUserPermissionsAndRoles();
  const record = useRecordContext();
  const { t } = useTranslation();

  const pendingReview = !!record.pendingPeerReviewRequest;

  return (
    isFinancer && (
      <FieldsGroup
        title={t('KYC information')}
        actions={!pendingReview && <ConfirmKycButton />}
      >
        <Grid container>
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <TextField source="creditScore" label={t('Credit score')} emptyText="-" />
            </SimpleShowLabeled>
          </Grid>
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <TextField source="creditScoreProvider" label={t('Credit score provider')} emptyText="-" />
            </SimpleShowLabeled>
          </Grid>
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <TextField source="paymentScore" label={t('Payment score')} emptyText="-" />
            </SimpleShowLabeled>
          </Grid>
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <TextField source="paymentScoreProvider" label={t('Payment score provider')} emptyText="-" />
            </SimpleShowLabeled>
          </Grid>
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <TextField source="financerScore" label={t('Financer score')} emptyText="-" />
            </SimpleShowLabeled>
          </Grid>
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <DateField label={t('KYC date')} showTime source="lastKycDoneDate" emptyText="-" />
            </SimpleShowLabeled>
          </Grid>
        </Grid>
      </FieldsGroup>
    )
  );
};

export default KycInformation;
