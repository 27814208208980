import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import {
  FormTab,
} from 'react-admin';

import { useTranslation } from 'react-i18next';

import useFeatureFlags from '../hooks/useFeatureFlags';
import useHasUserPermissions from '../hooks/useHasUserPermissions';

import { useConstantContext } from '../ConstantsContext';

import renderReference from '../../utils/renderReference';

import List from '../designSystem/react-admin/list/List';
import AutocompleteInput from '../designSystem/react-admin/inputs/AutocompleteInput';
import ReferenceInput from '../designSystem/react-admin/inputs/ReferenceInput';
import TabbedForm from '../designSystem/react-admin/TabbedForm';
import TitleH1 from '../designSystem/TitleH1';
import SelectInput from '../designSystem/react-admin/inputs/SelectInput';

import {
  OpenDatagrid,
  ClosedDatagrid,
  InvoicedDatagrid,
  ReadyToChargeDatagrid,
} from './BillingDatagrid';

export const getBillingAccountListTitle = (t) => t('Billings');

const getTabArray = (accountStatuses) => [
  accountStatuses.ACTIVE,
  accountStatuses.PARKED,
  accountStatuses.INVOICED,
  accountStatuses.CLOSED,
];

const BillingAccountLists = () => {
  const { constants, clientInfo } = useConstantContext();
  const { entityRoles, userActions, accountStatuses } = constants;
  const { t } = useTranslation();

  const { pathname } = useLocation();
  const routers = pathname.split('/');
  const tabIndex = routers[routers.length - 1];
  const tabArray = getTabArray(accountStatuses);
  const currencyTab = tabIndex.length > 1 ? 0 : Number(tabIndex);
  const [activeTab, setActiveTab] = useState(tabArray[currencyTab]);

  const [featureFlags] = useFeatureFlags();
  const canViewBilling = useHasUserPermissions(
    constants,
    userActions.VIEW_BILLING_ACCOUNTS,
  );

  const shouldShowPartnerInfo = !clientInfo.defaultPartnerFinancer;

  const handleChangeTab = (status) => {
    setActiveTab(status);
  };

  if (!featureFlags.enableBillingList || !canViewBilling) {
    return null;
  }

  return (
    <TabbedForm toolbar={null} sanitizeEmptyValues>
      <FormTab
        label={t('Open')}
        sx={{ alignItems: 'stretch' }}
        onClick={() => handleChangeTab(accountStatuses.ACTIVE)}
      >
        {activeTab === accountStatuses.ACTIVE && (
          <List
            key="Active-billing-list"
            disableSyncWithLocation
            sort={{ field: 'createdAt', order: 'DESC' }}
            filter={{ accountStatus: accountStatuses.ACTIVE }}
            mainFilterInputs={[
              <ReferenceInput
                source="debtorId"
                reference="entity-v2/reference"
                filter={{ entityRoles: entityRoles.MERCHANT }}
                alwaysOn
              >
                <AutocompleteInput
                  optionText={renderReference}
                  suggestionLimit={20}
                  placeholder={t('Merchant')}
                  shouldUseNarrowLabel
                />
              </ReferenceInput>,
              ...!shouldShowPartnerInfo ? [<SelectInput
                placeholder={t('Financer')}
                source="custom_financedByPartner"
                choices={[
                  { id: 'true', name: 'Moank AB' },
                  { id: 'false', name: 'Lidion Bank' },
                ]}
              />] : [],
            ]}
            appLocation="home.billing-account"
            title={<TitleH1 title={getBillingAccountListTitle(t)} />}
          >
            <OpenDatagrid shouldShowMerchantName />
          </List>
        )}
      </FormTab>
      <FormTab
        label={t('Ready to charge')}
        sx={{ alignItems: 'stretch' }}
        onClick={() => handleChangeTab(accountStatuses.PARKED)}
      >
        {activeTab === accountStatuses.PARKED && (
          <List
            key="Parked-billing-list"
            disableSyncWithLocation
            sort={{ field: 'createdAt', order: 'DESC' }}
            filter={{ accountStatus: accountStatuses.PARKED }}
            mainFilterInputs={[
              <ReferenceInput
                source="debtorId"
                reference="entity-v2/reference"
                filter={{ entityRoles: entityRoles.MERCHANT }}
                alwaysOn
              >
                <AutocompleteInput
                  optionText={renderReference}
                  suggestionLimit={20}
                  placeholder={t('Merchant')}
                  shouldUseNarrowLabel
                />
              </ReferenceInput>,
              ...!shouldShowPartnerInfo ? [<SelectInput
                placeholder={t('Financer')}
                source="custom_financedByPartner"
                choices={[
                  { id: 'true', name: 'Moank AB' },
                  { id: 'false', name: 'Lidion Bank' },
                ]}
              />] : [],
            ]}
            appLocation="home.billing-account"
            title={<TitleH1 title={getBillingAccountListTitle(t)} />}
          >
            <ReadyToChargeDatagrid shouldShowMerchantName />
          </List>
        )}
      </FormTab>
      <FormTab
        label={t('Invoiced')}
        sx={{ alignItems: 'stretch' }}
        onClick={() => handleChangeTab(accountStatuses.INVOICED)}
      >
        {activeTab === accountStatuses.INVOICED && (
          <List
            disableSyncWithLocation
            sort={{ field: 'createdAt', order: 'DESC' }}
            filter={{ accountStatus: accountStatuses.INVOICED }}
            mainFilterInputs={[
              // TODO: Invoice reference filters
              <ReferenceInput
                source="debtorId"
                reference="entity-v2/reference"
                filter={{ entityRoles: entityRoles.MERCHANT }}
                alwaysOn
              >
                <AutocompleteInput
                  optionText={renderReference}
                  suggestionLimit={20}
                  placeholder={t('Merchant')}
                  shouldUseNarrowLabel
                />
              </ReferenceInput>,
              ...!shouldShowPartnerInfo ? [<SelectInput
                placeholder={t('Financer')}
                source="custom_financedByPartner"
                choices={[
                  { id: 'true', name: 'Moank AB' },
                  { id: 'false', name: 'Lidion Bank' },
                ]}
              />] : [],
            ]}
            appLocation="home.billing-account"
            title={<TitleH1 title={getBillingAccountListTitle(t)} />}
          >
            <InvoicedDatagrid shouldShowMerchantName />
          </List>
        )}
      </FormTab>
      <FormTab
        label={t('Closed')}
        sx={{ alignItems: 'stretch' }}
        onClick={() => handleChangeTab(accountStatuses.CLOSED)}
      >
        {activeTab === accountStatuses.CLOSED && (
          <List
            disableSyncWithLocation
            sort={{ field: 'createdAt', order: 'DESC' }}
            filter={{ accountStatus: accountStatuses.CLOSED }}
            mainFilterInputs={[
              <ReferenceInput
                source="debtorId"
                reference="entity-v2/reference"
                filter={{ entityRoles: entityRoles.MERCHANT }}
                alwaysOn
              >
                <AutocompleteInput
                  optionText={renderReference}
                  suggestionLimit={20}
                  placeholder={t('Merchant')}
                  shouldUseNarrowLabel
                />
              </ReferenceInput>,
              ...!shouldShowPartnerInfo ? [<SelectInput
                placeholder={t('Financer')}
                source="custom_financedByPartner"
                choices={[
                  { id: 'true', name: 'Moank AB' },
                  { id: 'false', name: 'Lidion Bank' },
                ]}
              />] : [],
            ]}
            appLocation="home.billing-account"
            title={<TitleH1 title={getBillingAccountListTitle(t)} />}
          >
            <ClosedDatagrid shouldShowMerchantName />
          </List>
        )}
      </FormTab>
    </TabbedForm>
  );
};

export default BillingAccountLists;
