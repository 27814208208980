import React from 'react';
import {
  ArrayField,
  TextField,
  DateField,
  useRecordContext,
  WrapperField,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import IdentifyButton from './IdentifyButton';

import CurrencyField from '../utils/CurrencyField';
import RevertPaymentButton from '../utils/RevertPaymentButton';
import DropDownMenu from '../../utils/DropDownMenu';
import FieldsGroup from '../utils/FieldsGroup';

import SimpleShowLayout from '../designSystem/react-admin/SimpleShowLayout';
import Datagrid from '../designSystem/react-admin/Datagrid';
import Edit from '../designSystem/react-admin/Edit';
import TitleH1 from '../designSystem/TitleH1';

export const getUnidentifiedAccountTitle = (record) => {
  if (!record) return '';
  if (record.reference) return record.reference;
  if (record.payerName) {
    return `${record.payerName} - ${record.currency} ${record.amount}`;
  }
  return record.id;
};

const Title = () => {
  const record = useRecordContext();
  const { t } = useTranslation();
  const title = `${t('Unidentified payment')}: ${getUnidentifiedAccountTitle(record)}`;

  return <TitleH1 title={title} />;
};

const UnidentifiedAccountFieldsGroup = () => {
  const record = useRecordContext();
  const { t } = useTranslation();

  return (
    <FieldsGroup>
      <ArrayField source="transactions">
        <Datagrid bulkActionButtons={false} rightAlignedColumns={['amount', 'actions']}>
          <TextField source="type" />
          <TextField source="description" />
          <TextField source="payinDetails.reference" label={t('Reference')} />
          <TextField source="payinDetails.payerName" label={t('Payer name')} />
          <DateField source="date" label={t('Date')} emptyText="-" />
          <CurrencyField source="amount" currencySource="currency" label={t('Amount')} />
          <DropDownMenu>
            <WrapperField label={false} source="actions">
              <RevertPaymentButton />
              <IdentifyButton id={record.id} />
            </WrapperField>
          </DropDownMenu>
        </Datagrid>
      </ArrayField>
    </FieldsGroup>
  );
};

const UnidentifiedAccountEdit = () => (
  <Edit title={<Title />} appLocation="home.unidentified-account.edit">
    <SimpleShowLayout>
      <UnidentifiedAccountFieldsGroup />
    </SimpleShowLayout>
  </Edit>
);

export default UnidentifiedAccountEdit;
